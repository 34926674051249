import React, { useState } from "react";
import Button from "../../components/Button";
import PurchaseVoucher from "./verificationFormComponents/PurchaseVoucher";
import RegistrationForm from "./verificationFormComponents/RegistrationForm";
import RetrieveCode from "./verificationFormComponents/RetrieveCode";

function VerificationForm({ setStudent, schools, setScreen }) {
  const [errors, setErrors] = useState();
  const [logo, setLogo] = useState("");
  const [isloading] = useState(false);
  const [userInput, setUserInput] = useState({
    school: "",
    index_number: "",
    voucher_code: "",
  });

  const [page, setPage] = useState("initial");

  return (
    // <div className="col-md-5">
    <div className="card card-table table-height4 p-3">
      <div className="card-body booking_card">
        <div className="text-center">
          <img
            className="login-img"
            src={logo || "/assets/images/placeholder.png"}
            alt=""
          />
        </div>

        {page === "initial" && (
          <div>
            <div className="mt-4">
              <Button
                cssClasses={"btn btn-success w-100"}
                isloading={isloading}
                buttonText={"Purchase Voucher Code"}
                callback={() => setPage("purchase-voucher")}
              />
            </div>
            <div className="mt-4">
              <Button
                cssClasses={"btn btn-warning w-100"}
                isloading={isloading}
                buttonText={"Fill Registration Form"}
                callback={() => setPage("registration-form")}
              />
            </div>
            <div className="mt-4">
              <Button
                cssClasses={"btn btn-secondary w-100"}
                isloading={isloading}
                buttonText={"Retrieve Voucher Code"}
                callback={() => setPage("retrieve-code")}
              />
            </div>
          </div>
        )}
        {page === "purchase-voucher" && (
          <PurchaseVoucher
            userInput={userInput}
            schools={schools}
            setLogo={setLogo}
            setPage={setPage}
            setScreen={setScreen}
          />
        )}
        {page === "registration-form" && (
          <RegistrationForm
            errors={errors}
            setErrors={setErrors}
            userInput={userInput}
            setUserInput={setUserInput}
            setPage={setPage}
            setScreen={setScreen}
            setStudent={setStudent}
            schools={schools}
            setLogo={setLogo}
          />
        )}
        {page === "retrieve-code" && (
          <RetrieveCode
            userInput={userInput}
            // handleUserInput={handleUserInput}
            setScreen={setScreen}
            setStudent={setStudent}
            schools={schools}
            errors={errors}
            isloading={isloading}
            setPage={setPage}
          />
        )}
      </div>
    </div>
    // </div>
  );
}

export default VerificationForm;
