import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SideBar from "./Sidebar";
import axios from "axios";
import CheckInactivity from "../../../components/helpers/CheckInactivity";

const DashboardLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      axios.get("api/user").catch((err) => {
        console.log(err?.response);
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="main-wrapper">
      <CheckInactivity />
      <SideBar />
      <Outlet />
    </div>
  );
};

export default DashboardLayout;
