import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

const HouseForm = ({ data }) => {
  const [details, setDetails] = useState();

  useEffect(() => {
    setDetails(data?.student_details);
  }, [data]);
  console.log(data);
  return (
    <div className="col-lg-12">
      <div className="card card-table table-height3">
        <div className="card-body booking_card">
          <div className="head-cover text-center">
            <div>
              {parse(
                data?.school?.resource?.header ||
                  '<h3 className="text-capitalize">Loading...</h3>'
              )}
            </div>
            <div className="img-position">
              <img
                className="print-img img-fluid"
                src={`${process.env.REACT_APP_BACKEND_URL}/${data?.school?.image}`}
                alt=""
              />
            </div>
            <div className="admiss">
              <b className="text-capitalize">{details?.house?.title}</b>
            </div>
            <div align="right">
              <div className="wrapper1">
                <img
                  src={
                    details?.image === null
                      ? "/assets/images/default.png"
                      : `${process.env.REACT_APP_BACKEND_URL}/${details?.image}`
                  }
                  id="photo"
                  alt=""
                />
              </div>
            </div>
          </div>
          {Number(data?.school?.assign_house_to_day_students) === 1 && (
            <div className="stu_id1">
              <h4>Residential Status</h4>
              <div className="enroll-box text-center enroll-font">
                {data?.residencial_status}
              </div>
            </div>
          )}
          <div className="body-content">
            <div>
              <h3>Personal Information</h3>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  Full Name :{" "}
                  <span className="text-capitalize">{data?.name}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Gender :{" "}
                  <span className="text-capitalize">{data?.gender}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Place Of Birth :{" "}
                  <span className="text-capitalize">
                    {details?.place_of_birth}
                  </span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Hometown :{" "}
                  <span className="text-capitalize">{details?.hometown}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Nationality :{" "}
                  <span className="text-capitalize">
                    {details?.nationality}
                  </span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Health Challenge :{" "}
                  <span className="text-capitalize">
                    {details?.physically_challenged}
                  </span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Date Of Birth : <span>{details?.date_of_birth}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Religion :{" "}
                  <span className="text-capitalize">
                    {details?.religious_denomination}
                  </span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Residence :{" "}
                  <span className="text-capitalize">{details?.residence}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  NHIS Number : <span>{details?.nhis}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Department : <span>{details?.department}</span>
                </h4>
              </div>
            </div>
            <div className="mt-5">
              <h3>Guardian Information</h3>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Fullname : <span> {details?.guardian_name}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Nationality :{" "}
                  <span className="text-capitalize">
                    {" "}
                    {details?.guardian_nationality}
                  </span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Hometown :{" "}
                  <span className="text-capitalize">
                    {details?.guardian_hometown}
                  </span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Occupation :{" "}
                  <span className="text-capitalize">
                    {" "}
                    {details?.guardian_occupation}
                  </span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Contact : <span>{details?.guardian_phone}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Do they stay together? : <span>{details?.father_alive}</span>
                </h4>
              </div>
            </div>
            <div className="div-content mt-5">
              <h4>
                Housemaster / Housemistress contact :{" "}
                <span>{details?.house?.house_master_contact}</span>
              </h4>
            </div>
            <div className="row justify-content-between marg-top">
              <div className="col-6">
                <div>
                  <div className="line1"></div>
                  <div className="text-center mt-3">
                    <h4>Assist. Headmaster (Domestic)</h4>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="float-end">
                  <div className="line1"></div>
                  <div className="text-center mt-3">
                    <h4>Snr Housemaster / Housemistress</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="body-content dev-name-pos text-center mb-4">
            <h4>
              Developed by Samasoft Technologies (0558571128 / 0554922935)
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HouseForm;
