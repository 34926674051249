import React from "react";
import "react-quill/dist/quill.snow.css";
import useGetFetch from "../../components/customHooks/useGetFetch";
import DayProspectus from "./DayProspectus";
import Declaration from "./Declaration";
import FemaleProspectus from "./FemaleProspectus";
import Header from "./Header";
import MaleProspectus from "./MaleProspectus";
import Rules from "./Rules";

const Resources = () => {
  const {data:resource}=useGetFetch('/api/v1/school/resources/get');

  return (
    <div className="page-wrapper">
      <div className="content container-fluid mt-4">
        <div className="accordion" id="accordionExample">
          <Header resource={resource?.header}/>
          <Declaration resource={resource?.declaration}/>
          <Rules resource={resource?.rules}/>
          <MaleProspectus resource={resource?.boarding_prospectus_male}/>
          <FemaleProspectus resource={resource?.boarding_prospectus_female}/>
          <DayProspectus resource={resource?.day_prospectus}/>
        </div>
      </div>
    </div>
  );
};

export default Resources;
