import React from "react";

const Privacy = () => {
  return (
    <div className="container mt-5">
      <p
        style={{
          boxSizing: "border-box",
          margin: "6pt 0cm",
          fontFamily: "SF UI Display Bold",
          fontSize: "3rem",
          letterSpacing: "-0.015em",
          color: "rgb(0, 0, 0)",
          fontStyle: "normal",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontWeight: 400,
          orphans: 2,
          textAlign: "start",
          textIndent: "0px",
          textTransform: "none",
          whiteSpace: "normal",
          widows: 2,
          wordSpacing: "0px",
          WebkitTextStrokeWidth: "0px",
          backgroundColor: "rgb(251, 251, 251)",
          textDecorationThickness: "initial",
          textDecorationStyle: "initial",
          textDecorationColor: "initial",
        }}
      >
        <span
          style={{
            boxSizing: "border-box",
            fontSize: "2rem",
            color: "rgb(51, 51, 51)",
            letterSpacing: "0.75pt",
          }}
        >
          Privacy Policy
        </span>
      </p>
      <p
        style={{
          boxSizing: "border-box",
          marginTop: "0px",
          marginBottom: "11.25pt",
          color: "rgb(42, 42, 42)",
          fontFamily: '"SF UI Display Normal"',
          fontSize: "16px",
          fontStyle: "normal",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontWeight: 400,
          letterSpacing: "normal",
          orphans: 2,
          textAlign: "start",
          textIndent: "0px",
          textTransform: "none",
          whiteSpace: "normal",
          widows: 2,
          wordSpacing: "0px",
          WebkitTextStrokeWidth: "0px",
          backgroundColor: "rgb(251, 251, 251)",
          textDecorationThickness: "initial",
          textDecorationStyle: "initial",
          textDecorationColor: "initial",
        }}
      >
        <span
          style={{
            boxSizing: "border-box",
            fontSize: "18px",
            color: "rgb(51, 51, 51)",
          }}
        >
          Updated
        </span>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          : 13/03/2022
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
        }}
      >
        <br />
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
            background: "#FBFBFB",
          }}
        >
          This Privacy Policy describes the privacy practices of Samasoft
          Technologies, its subsidiaries(&quot;QuickSHSAdmit&rdquo;), and
          affiliates in connection with its website located at{" "}
          <a href="http://www.samasoftech.net">www.samasoftech.net</a>,
          <a href="http://www.QuickSHSAdmit.com">www.QuickSHSAdmit.com</a>, and
          it&apos;s iOS &amp; Android Apps, collectively the (&quot;Site&quot;).
          {/* Please review it carefully. Your use of the Site constitutes your
          agreement to this Privacy Policy. Please also review the&nbsp; */}
        </span>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#1E95D8",
            background: "#FBFBFB",
          }}
        >
          <span
            style={{
              boxSizing: "border-box",
              fontVariantLigatures: "normal",
              fontVariantCaps: "normal",
              orphans: 2,
              widows: 2,
              WebkitTextStrokeWidth: "0px",
              textDecorationThickness: "initial",
              textDecorationStyle: "initial",
              textDecorationColor: "initial",
              wordSpacing: "0px",
            }}
          >
            Terms of Service
          </span>
        </span>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
            background: "#FBFBFB",
          }}
        >
          <span
            style={{
              boxSizing: "border-box",
              fontVariantLigatures: "normal",
              fontVariantCaps: "normal",
              orphans: 2,
              widows: 2,
              WebkitTextStrokeWidth: "0px",
              textDecorationThickness: "initial",
              textDecorationStyle: "initial",
              textDecorationColor: "initial",
              wordSpacing: "0px",
            }}
          >
            &nbsp;which, in addition to this Privacy Policy, also govern your
            use of the Site and Samasoft Technologies services.
          </span>
        </span>
        <span
          style={{
            fontSize: "15px",
            fontFamily: '"Times New Roman", serif',
            color: "#47404F",
          }}
        >
          <br />
          &nbsp;
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"Times New Roman", serif',
              color: "#1E95D8",
            }}
          >
            1. WHAT KINDS OF INFORMATION DOES QuickSHSAdmit COLLECT?
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "22.5pt",
          textIndent: "-18.0pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "13px",
            fontFamily: '"Times New Roman", serif',
            color: "#1E95D8",
          }}
        >
          &middot;
        </span>
        <span
          style={{
            fontSize: "9px",
            fontFamily: '"Times New Roman", serif',
            color: "#1E95D8",
          }}
        >
          &nbsp; &nbsp; &nbsp;
        </span>
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"Times New Roman", serif',
              color: "#1E95D8",
            }}
          >
            1.1 PERSONALLY IDENTIFIABLE INFORMATION
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          Personally Identifiable Information (&quot;PII&rdquo;) is information
          that identifies a specific user. It may include your first name, last
          name, picture, address, e-mail address, telephone number, Tin Number,
          Business Registration Number, debit card information, bank account, or
          other payment service account information, social network account
          information such as Facebook account information, or any other
          information that specifically identifies a user.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          When you engage in certain activities on the Site, QuickSHSAdmit must
          collect PII from you to complete your order or otherwise process your
          transaction. Activities that require QuickSHSAdmit to collect PII
          include creating QuickSHSAdmit account, purchasing of event ticket,
          verification of event ticket, vote payment, submitting content (such
          as comments, reviews, photographs, or videos) to the Site, contacting
          QuickSHSAdmit with a question or concern, completing a survey, sending
          best practices and promotional email offers and redeeming or signing
          up for a special offer. You may access the Site without engaging in
          any of these activities. However, if you choose to engage in any of
          these activities, OpenCast will collect PII from you. Some of the PII
          is mandatory and some is optional based upon the certain activity.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          QuickSHSAdmit does not allow other parties to collect PII about an
          individual consumer&apos;s online activities over time and across
          different websites when users are on QuickSHSAdmit website.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "22.5pt",
          textIndent: "-18.0pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "13px",
            fontFamily: '"Times New Roman", serif',
            color: "#1E95D8",
          }}
        >
          &middot;
        </span>
        <span
          style={{
            fontSize: "9px",
            fontFamily: '"Times New Roman", serif',
            color: "#1E95D8",
          }}
        >
          &nbsp; &nbsp; &nbsp;
        </span>
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"Times New Roman", serif',
              color: "#1E95D8",
            }}
          >
            1.2 INFORMATION ABOUT HOW YOU USE THE SITE
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          Like most websites, QuickSHSAdmit uses cookies, web beacons, embedded
          scripts, and similar technologies to collect information about how you
          use the Site. The information collected may include information that
          your browser or device sends to our servers when you visit the Site,
          such as your IP address (which may tell us generally where you are
          located), the type of browser or device that you used to access the
          Site, the web page that led you to the Site, the search terms that you
          typed into a search engine to find the Site, and the web page you went
          to after leaving the Site. The information collected may also include
          the web pages you visited on the Site, the advertisements that were
          displayed, and the advertisements or links that you viewed or clicked
          on. QuickSHSAdmit&rsquo;s servers may collect similar information
          while you are logged on to the Site. The information collected may be
          anonymous or it may be associated with you.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          Emails that QuickSHSAdmit sends to you may include a web beacon or
          similar technology that tells QuickSHSAdmit whether you have received
          or opened the email or clicked on a link in the email.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          The Site may include content, links, or buttons from third party
          companies. QuickSHSAdmit may collect information about your use of
          these features. In addition, when you view or use a feature from a
          third party company, or the web page on which it appears, information
          from your browser may be sent to the third party company. You should
          review the third party company&rsquo;s privacy policy carefully to
          learn about its privacy practices.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "22.5pt",
          textIndent: "-18.0pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "13px",
            fontFamily: '"Times New Roman", serif',
            color: "#1E95D8",
          }}
        >
          &middot;
        </span>
        <span
          style={{
            fontSize: "9px",
            fontFamily: '"Times New Roman", serif',
            color: "#1E95D8",
          }}
        >
          &nbsp; &nbsp; &nbsp;
        </span>
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"Times New Roman", serif',
              color: "#1E95D8",
            }}
          >
            1.3 INFORMATION FROM SOCIAL NETWORKING SERVICES
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          If you access the Site from a social networking service such as
          Facebook, we will collect the information automatically provided by
          the social networking service under its applicable policies and any
          additional information that you permit the service to provide.
          QuickSHSAdmit does not control these social networking services and is
          not responsible for the information they provide. Before accessing the
          Site from a social networking service, you should review its privacy
          policy carefully to learn about its privacy practices.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"Times New Roman", serif',
              color: "#1E95D8",
            }}
          >
            2. HOW DOES QuickSHSAdmit USE THE INFORMATION THAT IT COLLECTS?
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          QuickSHSAdmit uses the PII and other information that it collects to
          activate and operate your QuickSHSAdmit account, authenticate and
          identify you for security and other purposes, fulfill your orders for
          products and services, communicate with you about your account or
          orders, respond to your feedback, questions, or concerns, contact you
          about new products or services or for other marketing purposes,
          administer contests, sweepstakes, surveys, and special offers,
          diagnose and troubleshoot problems with the Site, improve your
          experience with the Site, tailor advertising content to your
          interests, track information you have provided to us, such as your
          user preferences and preferences regarding receiving emails and other
          communications, and to analyze site usage for market research and
          other purposes.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          QuickSHSAdmit may also use the PII and other information that it
          collects to enforce its Terms of Use, Terms of Sale, or Privacy
          Policy, resolve disputes with you , prevent fraud or infringement of
          intellectual property rights, cooperate with law enforcement, and to
          comply with the law.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{ fontFamily: '"Times New Roman", serif', color: "#2A2A2A" }}
        >
          &nbsp;
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "22.5pt",
          textIndent: "-18.0pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "13px",
            fontFamily: '"Times New Roman", serif',
            color: "#1E95D8",
          }}
        >
          &middot;
        </span>
        <span
          style={{
            fontSize: "9px",
            fontFamily: '"Times New Roman", serif',
            color: "#1E95D8",
          }}
        >
          &nbsp; &nbsp; &nbsp;
        </span>
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"Times New Roman", serif',
              color: "#1E95D8",
            }}
          >
            2.1 HOW DOES QuickSHSAdmit STORE MY DATA
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          QuickSHSAdmit stores your encrypted information together with images
          on its server. QuickSHSAdmit fully owns an account with Stomerhost and
          fully controls the account. Your images and personal information are
          safe with QuickSHSAdmit. You have the right to request for deletion of
          your personal data from our system.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "22.5pt",
          textIndent: "-18.0pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "13px",
            fontFamily: '"Times New Roman", serif',
            color: "#1E95D8",
          }}
        >
          &middot;
        </span>
        <span
          style={{
            fontSize: "9px",
            fontFamily: '"Times New Roman", serif',
            color: "#1E95D8",
          }}
        >
          &nbsp; &nbsp; &nbsp;
        </span>
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"Times New Roman", serif',
              color: "#1E95D8",
            }}
          >
            2.2 WHY DOES Opencast UPLOAD IMAGES
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          QuickSHSAdmit optionally allows users to upload an image as their
          profile picture. Images uploaded to QuickSHSAdmit are stored on our
          server. It&apos;s safe to upload an image which is not mandatory on
          our platform and QuickSHSAdmit will no way sell or disclose your
          image(s) to any third party. You have the right to request for
          deletion of your personal data and including images uploaded to our
          system.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"Times New Roman", serif',
              color: "#1E95D8",
            }}
          >
            3. WILL QuickSHSAdmit SHARE MY INFORMATION?
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          QuickSHSAdmit will not rent, sell or lease your personal information
          to any unaffiliated person or entity without your express consent.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          QuickSHSAdmit will share your contact information, with event
          organizers with which you paid into their event (either by vote
          payment or event ticket purchasing).
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          QuickSHSAdmit works with other companies that help it run its
          business. These companies (&quot;Service Providers&rdquo;) may provide
          services to QuickSHSAdmit such as processing payments, delivering
          customer or technical support, sending emails on QuickSHSAdmit&rsquo;s
          behalf, fulfilling orders, administering contests, Affiliates
          Programs, and special offers, analyzing Site usage and other data, and
          running online advertising campaigns that display advertisements on
          the Site or on third party websites. These Service Providers may have
          access to your PII and other information that QuickSHSAdmit collects,
          but they are not permitted to use it for their own purposes without
          QuickSHSAdmit&rsquo;s expressed and authorized permission.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          QuickSHSAdmit may share, including with third party business partners
          and advertisers, information that does not specifically identify you,
          such as statistical information about usage of the Site and other
          aggregate information.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          QuickSHSAdmit may share your PII and other information that it
          collects:
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          textIndent: "-18.0pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "13px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          &middot;
        </span>
        <span
          style={{
            fontSize: "9px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </span>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          If it is required to do so in response to a subpoena or a court order
          or to comply with the law.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          textIndent: "-18.0pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "13px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          &middot;
        </span>
        <span
          style={{
            fontSize: "9px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </span>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          To enforce its Terms of Use, Terms of Sale, or Privacy Policy, or to
          resolve a dispute with you or a contestant to whom you voted or
          purchased ticket for.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          textIndent: "-18.0pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "13px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          &middot;
        </span>
        <span
          style={{
            fontSize: "9px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </span>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          If it has a good faith belief that disclosure is necessary to prevent
          fraud, intellectual property infringement, to prevent or respond to an
          actual or threatened security breach, or to otherwise protect the
          rights or safety of others.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          textIndent: "-18.0pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "13px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          &middot;
        </span>
        <span
          style={{
            fontSize: "9px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </span>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          If QuickSHSAdmit is merged with or acquired by another company, or if
          the Site or all or a substantial portion of QuickSHSAdmit&rsquo;s
          assets is acquired by another company, your PII and other information
          may be transferred as part of the transaction.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          textIndent: "-18.0pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "13px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          &middot;
        </span>
        <span
          style={{
            fontSize: "9px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        </span>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          If you have agreed to the sharing.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          Some portions of the Site may permit you to submit content for other
          users to see, such as comments, reviews, tickets, event details,
          contestants&rsquo; detail&rsquo;s, photographs, and videos. Content
          submitted to these portions of the Site will be public and not subject
          to this Privacy Policy. Please be careful when you submit content to
          these portions of the Site and do not submit anything that you do not
          wish to be public. Note that when you post content to these portions
          of the Site, your name and email address may be displayed.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"Times New Roman", serif',
              color: "#1E95D8",
            }}
          >
            3.1 INTERNET PROTOCOL (IP) ADDRESS
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          We may use your IP address to help diagnose problems with our server,
          and to administer our Web site. Your IP address is used to help
          identify you for the duration of a session and to gather broad
          demographic information.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"Times New Roman", serif',
              color: "#1E95D8",
            }}
          >
            3.2 CLICKSTREAM
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          As you browse the Internet, a trail of electronic information is left
          at each Web site you visit. This information, which is sometimes
          called &quot;clickstream&quot; data, can be collected and stored by a
          Web site&apos;s server. Clickstream data can tell us the type of
          computer and browsing software you use, the address of the Web site
          from which you linked to the QuickSHSAdmit web site, and in some
          instances, your e-mail address. We may use clickstream data to
          determine how much time visitors spend on each page of our site and
          how they navigate throughout our site. We will only use this
          information to improve the QuickSHSAdmit web site. Any collection or
          use of clickstream data will be anonymous and aggregate, and will not
          contain any personal information.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"Times New Roman", serif',
              color: "#1E95D8",
            }}
          >
            4. HOW DOES QuickSHSAdmit PROTECT THE INFORMATION IT COLLECTS?
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"Times New Roman", serif',
            color: "#333333",
          }}
        >
          QuickSHSAdmit understands the importance of protecting your PII.
          QuickSHSAdmit takes reasonable physical, administrative, and technical
          steps to secure the PII you provide. For example, QuickSHSAdmit
          secures its facilities, network, and servers, encrypts financial
          information and transmits it securely, and restricts access to PII to
          authorized personnel only. However, no security controls are 100%
          effective and QuickSHSAdmit cannot guarantee or warrant the security
          of your PII.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{ fontSize: "15px", fontFamily: "Roboto", color: "#47404F" }}
        >
          &nbsp;
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{ fontSize: "15px", fontFamily: "Roboto", color: "#47404F" }}
        >
          <br />
          &nbsp;
        </span>
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"SF UI Display Normal", serif',
              color: "#1E95D8",
            }}
          >
            YOUR PRIVACY CHOICES
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "22.5pt",
          textIndent: "-18.0pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: "SF UI Display Normal, serif",
            color: "#1E95D8",
          }}
        >
          &middot;
        </span>
        <span
          style={{
            fontSize: "9px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#1E95D8",
          }}
        >
          &nbsp; &nbsp; &nbsp;
        </span>
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"SF UI Display Normal", serif',
              color: "#1E95D8",
            }}
          >
            PROMOTIONAL EMAILS
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          If you do not want to receive QuickSHSAdmit&rsquo;s promotional emails
          you may opt out by clicking the &quot;Unsubscribe&rdquo; link at the
          bottom of any OpenCast's promotional email. You can also opt out at
          any time by contacting us at{" "}
          <a href="http://www.QuickSHSAdmit.com">www.QuickSHSAdmit.com</a> or
          sending us an email at&nbsp;
        </span>
        <u>
          <span
            style={{
              fontSize: "18px",
              fontFamily: '"SF UI Display Normal", serif',
              color: "#1E95D8",
            }}
          >
            <a href="mailto:info@samasoftech.net">info@samasoftech.net</a>
          </span>
        </u>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          &nbsp;or calling us at (+233) 558-571-228 and telling us that you do
          not wish to receive promotional emails.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          There are certain emails that we must send to you regarding your
          account, account activity, and other business operations. You may not
          opt out of these emails.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "22.5pt",
          textIndent: "-18.0pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: "SF UI Display Normal, serif",
            color: "#1E95D8",
          }}
        >
          &middot;
        </span>
        <span
          style={{
            fontSize: "9px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#1E95D8",
          }}
        >
          &nbsp; &nbsp; &nbsp;
        </span>
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"SF UI Display Normal", serif',
              color: "#1E95D8",
            }}
          >
            COOKIES
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "0cm",
          marginRight: "0cm",
          marginBottom: "11.25pt",
          marginLeft: "22.5pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          &quot;Cookies&quot; are pieces of information that are placed on an
          individual&apos;s computer hard drive to enable the individual to more
          easily communicate and interact with Web sites. QuickSHSAdmit may use
          cookies to customize your experience on the QuickSHSAdmit&rsquo;s web
          site. We may use cookies to deliver content specific to your
          interests, save your password (if you have registered with us) so you
          don&apos;t have to re-enter it each time you visit our site, and for
          other purposes. You may, however, disallow receiving cookies at any
          time through your web browser. QuickSHSAdmit will never use cookies to
          retrieve information from a computer that is unrelated to the
          QuickSHSAdmit&rsquo;s web site or your interaction with the
          QuickSHSAdmit&rsquo;s Web site.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"SF UI Display Normal", serif',
              color: "#1E95D8",
            }}
          >
            5. HOW DO WE RESPOND TO DO NOT TRACK SIGNALS
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          We do not support the Do Not Track browser setting. Do Not Track (or
          DNT) is a preference you can set in your browser to let the websites
          you visit know that you do not want them collecting certain
          information about you.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          For more details about Do Not Track, including how to enable or
          disable this preference, visit this Do Not Track webpage:&nbsp;
        </span>
        <span
          style={{
            fontFamily: '"SF UI Display Normal", serif',
            color: "#2A2A2A",
          }}
        >
          <a
            href="https://termsfeed.com/do-not-track"
            target="_blank"
            rel="noreferrer"
          >
            <span style={{ fontSize: "18px", color: "#1E95D8" }}>
              https://termsfeed.com/do-not-track
            </span>
          </a>
        </span>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          .
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"SF UI Display Normal", serif',
              color: "#1E95D8",
            }}
          >
            6. UPDATING OR CORRECTING YOUR INFORMATION
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          You can update or correct your PII by accessing your account on the
          Site. You can also contact us at&nbsp;
        </span>
        <u>
          <span
            style={{
              fontSize: "18px",
              fontFamily: '"SF UI Display Normal", serif',
              color: "#1E95D8",
            }}
          >
            <a href="http://www.QuickSHSAdmit.com">www.QuickSHSAdmit.com</a>
          </span>
        </u>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          &nbsp;or sending us an email at&nbsp;
        </span>
        <u>
          <span
            style={{
              fontSize: "18px",
              fontFamily: '"SF UI Display Normal", serif',
              color: "#1E95D8",
            }}
          >
            <a href="mailto:info@samasoftech.net">info@samasoftech.net</a>
          </span>
        </u>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          &nbsp;or calling us at (+233) 558-571-228. Please tell us your name,
          address, and the PII that you would like to update or correct.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          If you would like to delete your account or PII, please contact us at
          www.QuickSHSAdmit.com/privacy with your request. Please note that for
          legal and business reasons, we may need to retain certain records and
          information. In addition, some information may remain on back-up
          systems.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"SF UI Display Normal", serif',
              color: "#1E95D8",
            }}
          >
            7. LINKS TO OTHER WEBSITES
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          This Privacy Policy only addresses privacy practices for this Site.
          The Site may contain links to other websites, including third party
          websites. This Privacy Policy does not govern such other websites and
          QuickSHSAdmit is not responsible for the privacy practices of other
          websites. When you visit another website you should review carefully
          the privacy policy for that website.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"SF UI Display Normal", serif',
              color: "#1E95D8",
            }}
          >
            8. CHANGES TO THIS PRIVACY POLICY
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          QuickSHSAdmit may change this Privacy Policy at any time, for any
          reason, and without notice, by posting the amended Privacy Policy on
          the Site. The revised Privacy Policy will become effective on the date
          of posting. Your continued use of the Site after posting constitutes
          your agreement to the revised Privacy Policy. QuickSHSAdmit encourages
          you to check the Privacy Policy frequently for the latest information
          on its privacy practices.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginTop: "15.0pt",
          marginRight: "0cm",
          marginBottom: "7.5pt",
          marginLeft: "0cm",
          background: "#FBFBFB",
        }}
      >
        <strong>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"SF UI Display Normal", serif',
              color: "#1E95D8",
            }}
          >
            9. HOW TO CONTACT QuickSHSAdmit
          </span>
        </strong>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          If you have any questions or concerns about this Privacy Policy,
          please contact us by e-mail at&nbsp;
        </span>
        <u>
          <span
            style={{
              fontSize: "18px",
              fontFamily: '"SF UI Display Normal", serif',
              color: "#0D6EFD",
            }}
          >
            <a href="mailto:info@samasoftech.net">info@samasoftech.net</a>
          </span>
        </u>
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          &nbsp;or call us at (+233) 558-571-228.
        </span>
      </p>
      <p
        style={{
          margin: "0cm",
          fontSize: "16px",
          fontFamily: '"Calibri", sans-serif',
          marginBottom: "11.25pt",
          background: "#FBFBFB",
        }}
      >
        <span
          style={{
            fontSize: "18px",
            fontFamily: '"SF UI Display Normal", serif',
            color: "#333333",
          }}
        >
          Effective date: 13/03/2022
        </span>
      </p>
    </div>
  );
};

export default Privacy;
