import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PagePagination from "../../../components/PagePagination";
import Card from "../partials/Card";
import SvgIcons from "../partials/SvgIcons";
import Swal from "sweetalert2";

const Home = () => {
  // /api/v1/admission/stats
  const [page, setPage] = useState(0);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const handlePageClicked = (e) => {
    setPage(e.selected + 1);
    setReload(!reload);
  };
  const [data, setData] = useState();
  console?.log(data);
  useEffect(() => {
    axios
      .get(`/api/v1/admission/stats?page=${page}`)
      .then((res) => {
        if (res.data.status === 200) {
          setReload(true);
          setData(res?.data?.data);
        }
      })
      .catch((err) => {
        setReload(true);
        console.log(err?.response);
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
        if (err.response.status === 400) {
          Swal.fire({
            title: "Unauthorized Access",
            text: err?.response.data.error_msg,
            icon: "error",
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
            timer: 2000,
          });
          localStorage.clear();
          navigate("/login");
        }
      });
  }, [reload, navigate, page]);

  let role = localStorage.getItem("role");
  let is_affiliate = localStorage.getItem("is_affiliate");
  if (role?.toLowerCase() === "affiliate" && Number(is_affiliate) === 1) {
    Swal.fire({
      title: "Unauthorized Access",
      text: "This account doesn't have access to this dashboard",
      icon: "error",
      confirmButtonColor: "#011535",
      confirmButtonText: "Okay",
      timer: 2000,
    });
    localStorage.clear();
    navigate("/login");
  }

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12 mt-5">
              <h3 className="page-title mt-3 text-capitalize">
                {localStorage.getItem("school")}
              </h3>
            </div>
          </div>
        </div>

        <div className="row">
          <Card
            title="Total Admission"
            value={data?.total_admitted}
            svg={SvgIcons.awards}
          />
          <Card
            title="Total Registered Students"
            value={data?.total_registered}
            svg={SvgIcons.awards}
          />
          <Card
            title="Total Unregistered Students"
            value={data?.total_unregistered}
            svg={SvgIcons.awards}
          />
        </div>

        <div className="row">
          <div className="col-md-12 d-flex">
            <div className="card card-table flex-fill">
              <div className="card-header">
                <h4 className="card-title float-left mt-2">
                  Lists Of Registered Students
                </h4>
              </div>
              <div className="card-body booking_card">
                <div className="table-responsive">
                  <table
                    className="table table-hover table-center"
                    id="example"
                  >
                    <thead>
                      <tr>
                        <th>Reg_no</th>
                        <th className="text-center">Full Name</th>
                        <th className="text-center">Index Number</th>
                        <th className="text-center">Gender</th>
                        <th className="text-center">House</th>
                        <th className="text-center">Department</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.registered_list?.data?.map((item, i) => (
                        <tr key={i}>
                          <td className="text-nowrap text-capitalize">
                            {item?.student_details?.reg_no}
                          </td>
                          <td className="text-nowrap text-capitalize">
                            {item?.name}
                          </td>
                          <td className="text-center">{item?.index_number}</td>
                          <td className="text-center">{item?.gender}</td>
                          <td className="text-center">
                            {item?.student_details?.house?.title}
                          </td>
                          <td className="text-center">{item?.department}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <PagePagination
                    pageCount={data?.registered_list?.last_page}
                    handlePageClick={handlePageClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
