import axios from "axios";
import { useEffect, useState } from "react";
import Button from "../../components/Button";

const SchoolInformation = ({ setScreen, student, setStudent }) => {
  const [isLoading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState(student);
  const years = [2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015];

  console.log("stu", student?.school?.student_picture_required);
  useEffect(() => {
    setUserInput((userInput) => ({
      ...userInput,
      image: null,
    }));
  }, []);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`/api/v1/student/register?admission_id=${userInput?.id}`, userInput)
      .then((response) => {
        if (response?.data?.status === 200) {
          setStudent(response?.data?.data);
          setScreen("personal_info");
        }
      })
      .catch((err) => {
        console?.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="card card-table table-height2">
      <div className="card-header card-header-margin d-flex justify-content-between">
        <h3 className="my-auto">School & Personal Information</h3>
      </div>
      <div className="card-body booking_card">
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pad-0">
                <div className="row formtype">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Full Name</h4>
                      </label>
                      <input
                        className="form-control text-left text-capitalize"
                        type="text"
                        readOnly
                        value={student?.name}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Index Number</h4>
                      </label>
                      <input
                        className="form-control text-left"
                        type="text"
                        readOnly
                        value={student?.index_number}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Enrollment Pin *</h4>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="enrollment_pin"
                        value={userInput?.student_details?.enrollment_pin}
                        onChange={handleUserInput}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Year Group *</h4>
                      </label>
                      <select
                        className="form-control"
                        name="year_group"
                        value={userInput?.student_details?.year_group}
                        onChange={handleUserInput}
                        required
                      >
                        <option value="">---Select Year Group---</option>
                        <option value={"2022"}>2022</option>
                        <option value={"2023"}>2023</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Residential Status *</h4>
                      </label>
                      <input
                        type="text"
                        name="residential-status"
                        className="form-control text-capitalize"
                        readOnly
                        value={student?.residencial_status}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>House *</h4>
                      </label>
                      <input
                        type="text"
                        name="house"
                        readOnly
                        value={student?.student_details?.house?.title || ""}
                        className="form-control text-capitalize"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Aggregate Score *</h4>
                      </label>
                      <input
                        type="text"
                        name="aggregate-score"
                        className="form-control"
                        readOnly
                        value={student?.aggregate_score}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>BECE Year Completed *</h4>
                      </label>
                      <select
                        className="form-control"
                        value={userInput?.student_details?.year_completed}
                        name="year_completed"
                        required
                        onChange={handleUserInput}
                      >
                        <option value="">---Select Year---</option>
                        {years?.map((year) => (
                          <option value={year}>{year}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Program *</h4>
                      </label>
                      <input
                        type="text"
                        required
                        value={student?.department}
                        readOnly
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <h4>Name Of Last School Attended *</h4>
                      </label>
                      <input
                        type="text"
                        name="basic_school"
                        required
                        value={userInput?.student_details?.basic_school}
                        onChange={handleUserInput}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="d-flex float-end">
                      {Number(student?.school?.student_picture_required) ===
                        1 && (
                        <button
                          type="button"
                          className="btn btn-secondary px-md-5 me-3"
                          onClick={() => setScreen("picture-upload")}
                        >
                          Previous
                        </button>
                      )}

                      <Button
                        cssClasses={"btn btn-success px-md-5"}
                        buttonText="Save and Continue"
                        isloading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default SchoolInformation;
