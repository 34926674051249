/* eslint-disable react/style-prop-object */
import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
// import Opencast from "../../components/OpenCast";
import Donation from "../partials/Donation";
import Footer from "../partials/Footer";
import Nav from "../partials/Nav";
import VerificationForm from "./VerificationForm";

const Verification = ({ setStudent, schools, setScreen, student }) => {
  const [amount, setAmount] = useState("");

  const handlePaymentProcessing = (value) => {
    axios
      .post("/api/transaction/donation/payment", { amount: value })
      .then((res) => {
        // console.log(res?.data);
        if (res?.data?.success === true && res?.data?.status === 200) {
          window.location.replace(res?.data?.data?.trans_details?.checkoutUrl);
        }
      })
      .catch((err) => {
        // setSubmitting(false);
        Swal.fire({
          title: "Trasaction Failed",
          text: "Error Processing Payment, Please Try Again Later",
          icon: "error",
        });
      });
  };

  const handleDonation = () => {
    Swal.fire({
      title: "Enter Amount",
      input: "text",
      inputValue: amount,
      showCancelButton: true,
      confirmButtonText: "Donate",
      confirmButtonColor: "#0061FF",
      inputValidator: (value) => {
        if (!value) {
          return "Please enter an amount!";
        } else if (value <= 0) {
          return "Please enter an amount geater than 0!";
        } else if (!Number(value)) {
          return "Amount must be a number and graeter than 0!";
        }
      },
    }).then((result) => {
      if (result.value) {
        setAmount(result.value);
        Swal.fire({
          title: `Amount: GHS${result.value}`,
          text: "Processing Please Wait...",
          icon: "success",
          timer: 15000,
        });
        handlePaymentProcessing(result.value);
        // Further processing code goes here...
      }
    });
  };

  return (
    <div>
      <Nav />

      {/* Main Section */}
      <section className="container-fluid advert-margin">
        <div className="row">
          <div className="col-md-3">
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9265592512774781"
              crossorigin="anonymous"
            ></script>

            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-9265592512774781"
              data-ad-slot="7487431170"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
            <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
          </div>
          <div className="col-md-6 p-5">
            {/* <Opencast /> */}

            <VerificationForm
              setStudent={setStudent}
              schools={schools}
              setScreen={setScreen}
              student={student}
            />
          </div>
          <div className="col-md-3">
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9265592512774781"
              crossorigin="anonymous"
            ></script>

            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-9265592512774781"
              data-ad-slot="7487431170"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
            <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
          </div>
        </div>
      </section>

      {/* Before Donations */}
      <div className="container-fluid">
        <div className="row">
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9265592512774781"
            crossorigin="anonymous"
          ></script>

          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-9265592512774781"
            data-ad-slot="7487431170"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        </div>
      </div>

      <Donation handleDonation={handleDonation} />

      {/* Before Footer */}
      <div className="mt-5">
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9265592512774781"
          crossorigin="anonymous"
        ></script>

        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-9265592512774781"
          data-ad-slot="7487431170"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      </div>
      <Footer />
      {/* <Advertisement/> */}
    </div>
  );
};

export default Verification;
