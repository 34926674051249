import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const GuardianInformation = ({ setScreen, student, setStudent }) => {
  const navigate=useNavigate();
  const [isloading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState(student?.student_details);
  console.log(userInput);
  useEffect(() => {
    setUserInput((userInput) => ({
      ...userInput,
      image: null,
    }));
  }, []);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post("/api/v1/student/register", userInput)
      .then((response) => {
        if (response?.data?.status === 200) {
          setStudent(response?.data?.data);
          navigate('/print-page');
        }
      })
      .catch((err) => {
        console?.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="card card-table table-height2">
      <div className="card-header card-header-margin d-flex justify-content-between">
        <h3 className="my-auto">Guardian Information</h3>
      </div>
      <div className="card-body booking_card">
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pad-0">
                <div className="row formtype">
                  <div>
                    <h4>Guardian's Information</h4>
                  </div>
                  <div className="form-cover p-3">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Full Name *</h4>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="guardian_name"
                            value={userInput?.guardian_name}
                            onChange={handleUserInput}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Nationality</h4>
                          </label>
                          <input
                            type="text"
                            name="guardian_nationality"
                            value={userInput?.guardian_nationality}
                            onChange={handleUserInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Hometown</h4>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="guardian_hometown"
                            value={userInput?.guardian_hometown}
                            onChange={handleUserInput}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Occupation *</h4>
                          </label>
                          <input
                            type="text"
                            name="guardian_occupation"
                            required
                            value={userInput?.guardian_occupation}
                            onChange={handleUserInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Telephone/Mobile Number *</h4>
                          </label>
                          <input
                            type="text"
                            name="guardian_phone"
                            value={userInput?.guardian_phone}
                            required
                            onChange={handleUserInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Do you stay together? *</h4>
                          </label>
                          <select
                            className="form-control"
                            name="guardian_alive"
                            value={userInput?.father_alive}
                            onChange={handleUserInput}
                            required
                          >
                            <option value="">-- Select Option--</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="d-flex float-end mt-4">
                      <button
                        type="button"
                        className="btn btn-secondary px-md-5 me-3"
                        onClick={() => setScreen("parents_info")}
                      >
                        Previous
                      </button>
                      <Button
                        cssClasses={"btn btn-success px-md-5"}
                        buttonText="Save and Continue"
                        isloading={isloading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default GuardianInformation;
