import React from "react";
import { useNavigate } from "react-router-dom";
import Nav from "./partials/Nav";

const SuccessPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Nav />
      <div className="container error-cover">
        <div>
          <img src="/assets/images/success.webp" alt="" className="img-fluid" />
        </div>
        <div>
          <h4 className="text-success">
            PAYMENT TRANSACTION <span>SUCCESSFUL</span>
          </h4>
        </div>
        <div className="d-flex justify-content-center">
          <div className="p-2">
            <button
              type="button"
              onClick={() => navigate("/")}
              className="btn btn-outline-primary"
            >
              Go to HomePage
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuccessPage;
