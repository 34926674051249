import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const useGetFetch = (url, reload) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isloaded, setIsLoaded] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setIsLoaded(false);
    axios
      .get(url)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoaded(true);
          setData(res.data.data);
        }
      })
      .catch((err) => {
        setIsLoaded(true);
        setErrors(err?.response);
        console.log(err?.response);
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  }, [url, reload, navigate]);

  return { data, isloaded, errors };
};

export default useGetFetch;
