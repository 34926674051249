import React from "react";

function Donation({ handleDonation }) {
  return (
    <div className="container-fluid gradient-bg">
      <div className="d-flex justify-content-center">
        <div className="image-section">
          <img
            src="/assets/images/donation.png"
            alt="donation"
            width={"60%"}
            className="mt-4"
          />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-left text-white">
          <h2>DONATE</h2>
          <p>
            Invest in the future by supporting our students today:
            <br /> donate to our school and help shape tomorrow's leaders.
          </p>
          <button
            className="btn btn-success d-none d-md-block  w-50 text-nowrap"
            onClick={handleDonation}
          >
            <i className="fa fa-handshake-o"></i>&nbsp; Donate
          </button>
          <button
            className="btn btn-success w-100 d-block d-md-none text-nowrap"
            onClick={handleDonation}
          >
            <i className="fa fa-handshake-o"></i>&nbsp; Donate
          </button>
        </div>
      </div>
    </div>
  );
}

export default Donation;
