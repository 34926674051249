import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import convertDate from "../../components/helpers/ConverDate";

const AdmissionForm = ({ data }) => {
  const [details, setDetails] = useState();

  useEffect(() => {
    setDetails(data?.student_details);
  }, [data]);

  return (
    <div className="col-lg-12">
      <div className="card card-table table-height3">
        <div className="card-body booking_card">
          <div className="head-cover text-center">
            <div>
              {parse(data?.school?.resource?.header || "<h3>Loading</h3>")}
            </div>
            <div className="img-position">
              <img
                className="print-img img-fluid"
                src={`${process.env.REACT_APP_BACKEND_URL}/${data?.school?.image}`}
                alt=""
              />
            </div>
            <div className="admiss">
              <b>ADMISSION FORM</b>
            </div>
            <div align="right">
              <div className="wrapper1">
                <img
                  src={
                    details?.image === null
                      ? "/assets/images/default.png"
                      : `${process.env.REACT_APP_BACKEND_URL}/${details?.image}`
                  }
                  id="photo"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="body-content">
            <div>
              <h3>Personal Information</h3>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  Full Name :{" "}
                  <span className="text-capitalize">{data?.name}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Gender :{" "}
                  <span className="text-capitalize">{data?.gender}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Place Of Birth :{" "}
                  <span className="text-capitalize">
                    {details?.place_of_birth}
                  </span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Hometown :{" "}
                  <span className="text-capitalize">{details?.hometown}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Nationality :{" "}
                  <span className="text-capitalize">
                    {details?.nationality}
                  </span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Health Challenge :{" "}
                  <span className="text-capitalize">
                    {details?.physically_challenged}
                  </span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Date Of Birth :{" "}
                  <span className="text-capitalize">
                    {details?.date_of_birth}
                  </span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Religion :{" "}
                  <span className="text-capitalize">
                    {details?.religious_denomination}
                  </span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Residence :{" "}
                  <span className="text-capitalize">{details?.residence}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  NHIS Number :{" "}
                  <span className="text-capitalize">{details?.nhis}</span>
                </h4>
              </div>
            </div>
            <div className="mt-5">
              <h3>School Information</h3>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Enrollment Pin : <span>{details?.enrollment_pin}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Program :{" "}
                  <span className="text-capitalize">{data?.department}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Form :{" "}
                  <span className="text-capitalize">{details?.form}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Admission Date :{" "}
                  <span className="text-capitalize">
                    {convertDate(data?.created_at).substring(0, 10)}
                  </span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Year Group : <span>{details?.year_group}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Residential Status :{" "}
                  <span className="text-capitalize">
                    {data?.residencial_status}
                  </span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  House :{" "}
                  <span className="text-capitalize">
                    {details?.house?.title}
                  </span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Aggregate Score : <span>{data?.aggregate_score}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Year Completed : <span>{details?.year_completed}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  Name Of Last School Attended :{" "}
                  <span className="text-capitalize">
                    {details?.basic_school}
                  </span>
                </h4>
              </div>
              <div className="div-content-2 d-flex">
                <h4>class :</h4>
                <div className="line my-auto ms-2"></div>
              </div>
            </div>
            <div className="mt-5">
              <h3>Parent Information</h3>
            </div>
            <div>
              <h4>Father</h4>
            </div>
            <div className="div-content-cover">
              <div className="div-content">
                <div className="div-content-2">
                  <h4>
                    Full Name :{" "}
                    <span className="text-capitalize">
                      {" "}
                      {details?.father_name}
                    </span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Nationality :{" "}
                    <span className="text-capitalize">
                      {" "}
                      {details?.father_nationality}
                    </span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Hometown :{" "}
                    <span className="text-capitalize">
                      {details?.father_hometown}
                    </span>
                  </h4>
                </div>
              </div>
              <div className="div-content">
                <div className="div-content-2">
                  <h4>
                    Occupation :{" "}
                    <span className="text-capitalize">
                      {" "}
                      {details?.father_occupation}
                    </span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Contact :{" "}
                    <span className="text-capitalize">
                      {details?.father_phone}
                    </span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Is father Alive? :{" "}
                    <span className="text-capitalize">
                      {details?.father_alive}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <h4>Mother</h4>
            </div>
            <div className="div-content-cover">
              <div className="div-content">
                <div className="div-content-2">
                  <h4>
                    Full Name :{" "}
                    <span className="text-capitalize">
                      {details?.mother_name}
                    </span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Nationality :{" "}
                    <span className="text-capitalize">
                      {details?.mother_nationality}
                    </span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Hometown :{" "}
                    <span className="text-capitalize">
                      {details?.mother?.hometown}
                    </span>
                  </h4>
                </div>
              </div>
              <div className="div-content">
                <div className="div-content-2">
                  <h4>
                    Occupation :{" "}
                    <span className="text-capitalize">
                      {details?.mother_occupation}
                    </span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Contact : <span>{details?.mother_phone}</span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Is Mother Alive? :{" "}
                    <span className="text-capitalize">
                      {details?.mother_alive}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <h3>Guardian Information</h3>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Full Name :{" "}
                  <span className="text-capitalize">
                    {details?.guardian_name}
                  </span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Nationality :{" "}
                  <span className="text-capitalize">
                    {" "}
                    {details?.guardian_nationality}
                  </span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Hometown :{" "}
                  <span className="text-capitalize">
                    {details?.guardian_hometown}
                  </span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Occupation :{" "}
                  <span className="text-capitalize">
                    {details?.guardian_occupation}
                  </span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Contact : <span>{details?.guardian_phone}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Do You Stay Together? : <span>{details?.guardian_alive}</span>
                </h4>
              </div>
            </div>
            <div className="mt-5 float-end">
              <div className="line1"></div>
              <div className="text-center mt-3">
                <h4>Headmaster</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="body-content dev-name-pos text-center mb-4">
            <h4>
              Developed by Samasoft Technologies (0558571128 / 0554922935)
            </h4>
            {/* <h4><a href='https://www.opencastgh.com' rel='noreferrer' target='_blank'>https://www.opencastgh.com</a></h4> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmissionForm;
