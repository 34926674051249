import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
const AddNewHouse = () => {
  const navigate = useNavigate();
  const [isloading, setLoading] = useState(false);
  const [errors, setErrors] = useState();

  const [userInput, setUserInput] = useState({
    title: "",
    gender_type: "",
    is_active: 1,
    capacity: 0,
    house_master_contact: "",
    is_day_house: "",
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post("/api/v1/house/create", userInput)
      .then((res) => {
        setLoading(false);
        Swal.fire(
          "Success",
          "House has been created successfully",
          "success"
        ).then((_) => navigate(-1));
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err?.response?.data?.error_msg);
        console.log(err?.response);
      });
  };

  return (
    <div className="page-wrapper mt-5 mt-md-0">
      <div className="content container">
        <div className="page-header"></div>

        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="card card-shadow">
              <div className="card-header">
                <div className="d-flex flex-row justify-content-between">
                  <div className="p-2">
                    <h3>Add New House</h3>
                  </div>
                  <div className="p-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row formtype">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>House Title *</label>
                              <input
                                className="form-control"
                                type="text"
                                name="title"
                                value={userInput?.title}
                                onChange={handleUserInput}
                                required
                              />
                            </div>
                            <small className="text-danger text-center">
                              {errors?.title}
                            </small>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>House Master / Mistress Contact</label>
                              <input
                                type="number"
                                name="house_master_contact"
                                value={userInput?.house_master_contact}
                                onChange={handleUserInput}
                                className="form-control"
                              />
                            </div>
                            <small className="text-danger text-center">
                              {errors?.house_master_contact}
                            </small>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Capacity</label>
                              <input
                                type="number"
                                name="capacity"
                                value={userInput?.capacity}
                                onChange={handleUserInput}
                                required
                                min={0}
                                className="form-control"
                              />
                            </div>
                            <small className="text-danger text-center">
                              {errors?.capacity}
                            </small>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Gender</label>
                              <select
                                className="form-control"
                                required
                                name="gender_type"
                                value={userInput?.gender_type}
                                onChange={handleUserInput}
                              >
                                <option value="">-- Select Option--</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                            </div>
                            <small className="text-danger text-center">
                              {errors?.gender_type}
                            </small>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Status</label>
                              <select
                                className="form-control"
                                required
                                value={userInput?.is_active}
                                onChange={handleUserInput}
                              >
                                <option value="">-- Select Option--</option>
                                <option value={1}>Active</option>
                                <option value={0}>Blocked</option>
                              </select>
                            </div>
                            <small className="text-danger text-center">
                              {errors?.is_active}
                            </small>
                          </div>
                          <div className="col-md-6">
                            <label>Is Day Hosue?</label>
                            <select
                              className="form-control"
                              required
                              name="is_day_house"
                              value={userInput?.is_day_house}
                              onChange={handleUserInput}
                            >
                              <option value="">-- Select Option--</option>
                              <option value={1}>Yes</option>
                              <option value={0}>No</option>
                            </select>
                            <small className="text-danger text-center">
                              {errors?.is_day_house}
                            </small>
                          </div>
                          <Button
                            cssClasses={
                              "btn btn-primary bg-default buttonedit1"
                            }
                            buttonText={"Create House"}
                            isloading={isloading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddNewHouse;
