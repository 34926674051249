import React from "react";
import Button from "../../../components/Button";
import Card from "../partials/Card";
import SvgIcons from "../partials/SvgIcons";
import { useNavigate } from "react-router-dom";
import useGetFetch from "../../../components/customHooks/useGetFetch";
import Spinners from "../../../components/Spinners";

const Houses = () => {
  const navigate = useNavigate();

  const { data, isloaded } = useGetFetch(
    `/api/v1/school/${localStorage.getItem("school_id")}`
  );

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="row mt-5">
          <Card
            title="Total Houses"
            value={data?.houses?.length}
            svg={SvgIcons.awards}
          />
          <Card
            title="Active Houses"
            value={
              data?.houses?.filter((item) => Number(item.is_active) === 1)
                .length
            }
            svg={SvgIcons.awards}
          />
          <Card
            title="Blocked Houses"
            value={
              data?.houses?.filter((item) => Number(item.is_active) === 0)
                .length
            }
            svg={SvgIcons.awards}
          />
        </div>

        <div className="row">
          <div className="col-md-12 d-flex">
            <div className="card card-table flex-fill card-h">
              <div className="card-header d-flex justify-content-between">
                <div>
                  <h4 className="card-title float-left mt-2">
                    Lists Of Houses{" "}
                  </h4>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate("/dashboard/add/house")}
                  >
                    Add New House
                  </button>
                </div>
              </div>
              <div className="card-body booking_card">
                <div className="d-flex justify-content-center align-content-center">
                  <div className="w-50 mb-2">
                    <input
                      type="text"
                      className="form-control text-center"
                      placeholder="Search for house"
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    className="table table-hover table-center"
                    id="example"
                  >
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th className="text-center">Gender</th>
                        <th className="text-center">Capacity</th>
                        <th className="text-center">Occupied</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded ? (
                        <>
                          {data?.houses?.map((house, i) => (
                            <tr key={i}>
                              <td className="text-nowrap">{house?.title}</td>
                              <td className="text-nowrap text-center text-capitalize">
                                {house?.gender_type}
                              </td>
                              <td className="text-center">{house?.capacity}</td>
                              <td className="text-center">{house?.occupied}</td>
                              <td className="text-center">
                                {Number(house?.is_active) === 1 && (
                                  <span className="bg-success-light mr-2 py-1 px-2 text-center text-capitalize">
                                    Active
                                  </span>
                                )}
                                {Number(house?.is_active) === 0 && (
                                  <span className="bg-danger-light mr-2 py-1 px-2 text-center text-capitalize">
                                    Blocked
                                  </span>
                                )}
                              </td>
                              <td className="text-center">
                                <div>
                                  <Button
                                    buttonText={
                                      <span>
                                        <i className="fas fa-pencil-alt"></i>
                                      </span>
                                    }
                                    cssClasses={"btn btn-outline-info"}
                                    callback={() =>
                                      navigate(
                                        `/dashboard/house/${house?.id}/edit`
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <div className="no-data">{Spinners.rotatingLines}</div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Houses;
