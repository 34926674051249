import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import PagePagination from "../../../components/PagePagination";

const AdmissionList = () => {
  const [isloading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [excel_file, setFile] = useState();
  const [pages, setPage] = useState(0);

  const handlefileUpload = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("excel_file", excel_file);
      const res = await axios.post("/api/v1/admission/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res?.status === 200) {
        setReload(!reload);
        Swal.fire(
          "Success",
          "Admission List has been Uploaded Successfully",
          "success"
        );
      }
      if (res?.data[0]?.errors) {
        Swal.fire(
          "Error",
          `${res?.data?.map((item) => {
            return `${item?.errors} <br/>This issue occured in row ${item?.row},column ${item?.attribute}<br/>`;
          })}`,
          "info"
        );
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      Swal.fire(
        "Failed",
        err?.response?.data?.error_msg || "Failed to upload the excel file",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const [reload, setReload] = useState(false);
  const [students, setStudents] = useState();

  useEffect(() => {
    axios
      .get(`/api/v1/admission/list?page=${pages}`)
      .then((res) => {
        if (res.data.status === 200) {
          setReload(true);
          setStudents(res.data.data);
        }
      })
      .catch((err) => {
        setReload(true);
        console.log(err?.response);
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  }, [reload, navigate, pages]);

  const handlePageClicked = (e) => {
    setPage(e.selected + 1);
    setReload(!reload);
  };

  const [search, setSearch] = useState("");

  const filteredList = students?.data?.filter((student) => {
    if (search === "") {
      return student;
    } else {
      if (
        student?.name?.toLowerCase().includes(search.toLowerCase()) ||
        student?.index_number?.toLowerCase().includes(search.toLowerCase())
      ) {
        return student;
      }
      return null;
    }
  });

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="col-md-12">
          <div className="card card-table flex-fill mt-5">
            <div className="card-header d-flex justify-content-between">
              <div>
                <h4 className="card-title float-left mt-2">Admission List</h4>
              </div>
              <form onSubmit={handlefileUpload}>
                <div className="d-flex flex-row ">
                  <input
                    type="file"
                    required
                    className="btn-primary form-control text-white me-2"
                    id="upload"
                    name={excel_file}
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  <Button
                    buttonText={"Upload"}
                    isloading={isloading}
                    cssClasses={"btn btn-outline-success mr-2"}
                  />
                  {/* <Button
                    buttonText={"Template"}
                    isloading={isloading}
                    cssClasses={"btn btn-outline-info text-nowrap"}
                  /> */}
                  <a
                    href="/assets/Admissions.xls"
                    target={"_self"}
                    className="btn btn-outline-info text-nowrap"
                  >
                    Template
                  </a>
                </div>
              </form>
            </div>
            <div className="card-body booking_card">
              <div className="d-flex justify-content-center align-content-center">
                <div className="w-100 mb-4">
                  <input
                    type="text"
                    value={search}
                    name="serach"
                    onChange={(e) => setSearch(e.target.value)}
                    className="form-control text-center"
                    placeholder="Search for student"
                  />
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover table-center" id="example">
                  <thead>
                    <tr>
                      <th>Index Number</th>
                      <th className="text-left">Full Name</th>
                      <th className="text-left">Gender</th>
                      <th className="text-center">Grade</th>
                      <th className="text-left">Department</th>
                      <th className="text-left">Residence</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredList?.map((student, i) => (
                      <tr key={i}>
                        <td className="text-nowrap">{student?.index_number}</td>
                        <td className="text-nowrap text-left text-capitalize">
                          {student?.name}
                        </td>
                        <td className="text-left text-capitalize">
                          {student?.gender}
                        </td>
                        <td className="text-center text-capitalize">
                          {student?.aggregate_score}
                        </td>
                        <td className="text-left text-capitalize">
                          {student?.department}
                        </td>
                        <td className="text-left">
                          {student?.residencial_status}
                        </td>
                      </tr>
                    ))}
                    {}
                  </tbody>
                </table>
                <PagePagination
                  pageCount={students?.last_page}
                  handlePageClick={handlePageClicked}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmissionList;
