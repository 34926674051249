import AdmissionForm from "./AdmissionForm";
import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import SisForm from "./SisForm";
import HouseForm from "./HouseForm";
import useGetFetch from "../../components/customHooks/useGetFetch";
import { useNavigate } from "react-router-dom";
import Declaration from "./Declaration";
// import Propspectus from "./Prospectus";
import Rules from "./Rules";
import OfferLetter from "./OfferLetter";

const PrintPage = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Admission Form",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage?.getItem("index_number")) {
      navigate("/");
    }
  }, [navigate]);

  const { data } = useGetFetch(
    `/api/v1/student/${localStorage.getItem("index_number")}`
  );

  const handleDonation = () => {
    localStorage.removeItem("index_number");
    navigate("/");
  };
  console.log("print", data?.school?.id);
  if (data?.school?.id === 14) {
    console.log("yes");
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row pt-5">
          <PrintButton
            handlePrint={handlePrint}
            handleDonation={handleDonation}
          />
          <div className="card card-table table-height">
            <div
              className="card-body"
              ref={componentRef}
              style={{ width: "100%" }}
            >
              {data?.school?.resource?.declaration && (
                <div className="page-break">
                  <Declaration data={data} />
                </div>
              )}
              {data?.school?.id === 14 && (
                <div className="page-break">
                  <OfferLetter data={data} />
                </div>
              )}
              <div className="page-break">
                <AdmissionForm data={data} />
              </div>
              <div className="page-break">
                <SisForm data={data} />
              </div>
              <div className="page-break">
                <HouseForm data={data} />
              </div>
              {/* <div className="page-break">
                <Propspectus data={data} />
              </div> */}

              <div className="page-break">
                <Rules data={data} />
              </div>
              {/* <div className="page-break">
                <MedicalRecord />
              </div> */}
            </div>
          </div>
          <PrintButton
            handlePrint={handlePrint}
            handleDonation={handleDonation}
          />
        </div>
      </div>
    </>
  );
};
export default PrintPage;

const PrintButton = ({ handlePrint, handleDonation }) => {
  return (
    <div className="col-lg-11 mb-3">
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-primary btn-mobile px-md-5 me-3"
          onClick={handlePrint}
        >
          Print
        </button>
        <button
          className="btn btn-success btn-mobile px-md-5"
          onClick={handleDonation}
        >
          Log Out
        </button>
      </div>
    </div>
  );
};
