import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import routes from "../../components/helpers/routes";
import "./styles/authDesktop.css";
import "./styles/authMobile.css";
import "./styles/authTablet.css";

const Login = () => {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState();
  const [isloading, setLoading] = useState(false);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/api/v1/auth/login",userInput)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === 200) {
          localStorage.setItem("token", res?.data?.data?.token);
          localStorage.setItem("school_id", res?.data?.data?.user?.school?.id);
          localStorage.setItem("school", res?.data?.data?.user?.school?.name);
          localStorage.setItem("name", res?.data?.data?.user?.name);
          localStorage.setItem("logo", res?.data?.data?.user?.school?.image);
          localStorage.setItem("role", res?.data?.data?.user?.role);
          localStorage.setItem("is_affiliate", res?.data?.data?.user?.is_affiliate)
          navigate(routes.DASHBOARD);
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err?.response?.data?.error_msg);
        console.log(err?.response);
      });
  };
  return (
    <div className="container container-position">
      <div className="col-lg-12 pad-left">
        <div className="container-wrapper">
          <form onSubmit={handleSubmit}>
            <div className="p-4 d-flex flex-column h-100 justify-content-center">
              <h1 className="text-center my-5">Login</h1>
              <div className="right d-flex flex-row position-relative">
                <input
                  type="email"
                  className="flex-fill form-control"
                  name="email"
                  value={userInput?.email}
                  onChange={handleUserInput}
                  placeholder="Email"
                  required
                  autoComplete="email"
                />
                <i className="far fa-envelope email position-absolute"></i>
              </div>
              <small className="text-danger text-center">{errors?.email}</small>
              <div className="left d-flex flex-row position-relative mt-4">
                <input
                  type="password"
                  className="flex-fill form-control"
                  name="password"
                  value={userInput?.password}
                  onChange={handleUserInput}
                  required
                  autoComplete="current-password"
                  placeholder="Password"
                />
                <i className="fas fa-lock password position-absolute"></i>
              </div>
              <small className="text-danger text-center">{errors?.password}</small>
              <div className="d-flex flex-row justify-content-center mt-4">
                <Button
                  cssClasses={"btn btn-primary px-5"}
                  buttonText={"Login"}
                  isloading={isloading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
