/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const TopBar = () => {
  return (
    <div className="header">
      <div className="header-left">
        <Link to="/dashboard/home" className="logo">
          <img
            src="/assets/images/student-icon.png"
            width="50"
            height="70"
            alt="logo"
            className=""
          />{" "}
          <span className="text-white my-auto">Admissions</span>{" "}
        </Link>
      </div>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
        }}
        id="toggle_btn"
      >
        <i className="fe fe-text-align-left"></i>
      </a>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
        }}
        className="mobile_btn"
        id="mobile_btn"
      >
        <i className="fas fa-bars"></i>
      </a>

      <div className="top-nav-search">
        <form>
          <input
            type="text"
            className="form-control"
            placeholder="Search here"
          />
          <button className="btn" type="submit">
            <i className="fas fa-search"></i>
          </button>
        </form>
      </div>
    </div>
  );
};

export default TopBar;
