/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Nav = () => {
  const [amount, setAmount] = useState("");

  const handlePaymentProcessing = (value) => {
    axios
      .post("/api/transaction/donation/payment", { amount: value })
      .then((res) => {
        if (res?.data?.success === true && res?.data?.status === 200) {
          window.location.replace(res?.data?.data?.original?.data?.checkoutUrl);
        }
      })
      .catch((err) => {
        // setSubmitting(false);
        Swal.fire({
          title: "Trasaction Failed",
          text: "Error Processing Payment, Please Try Again Later",
          icon: "error",
        });
      });
  };

  const handleDonation = () => {
    Swal.fire({
      title: "Enter Amount",
      input: "text",
      inputValue: amount,
      showCancelButton: true,
      confirmButtonText: "Donate",
      confirmButtonColor: "#0061FF",
      inputValidator: (value) => {
        if (!value) {
          return "Please enter an amount!";
        } else if (value <= 0) {
          return "Please enter an amount geater than 0!";
        } else if (!Number(value)) {
          return "Amount must be a number and graeter than 0!";
        }
      },
    }).then((result) => {
      if (result.value) {
        setAmount(result.value);
        Swal.fire({
          title: `Amount: GHS${result.value}`,
          text: "Processing Please Wait...",
          icon: "success",
          timer: 15000,
        });
        // Further processing code goes here...
        handlePaymentProcessing(result.value);
      }
    });
  };

  const showContactInfo = () => {
    Swal.fire({
      title: "Developer Information",
      html: `
              <p><strong>Name:</strong>Samasoft Technologies</p>
              <p><strong>Email:</strong> info@samasoftech.net</p>
              <p><strong>Website:</strong> https://samasoftech.net</p>
              <p><strong>Phone:</strong> (+233) 558-571228 / (+233) 554-922935</p>
              <hr />
              <p><strong>Follow us on:</strong></p>
              <div classname="d-flex flex-row justify-content-center">
                <a href="https://www.facebook.com/samasoftechnologies" target="_blank">
                    <i className="fa fa-facebook-square"></i> Facebook
                </a>
                
                <a href="https://www.instagram.com/opencastgh" target="_blank">
                    <i className="fa fa-instagram"></i> Instagram
                </a>
                
                <a href="https://twitter.com/SAMZY90234896" target="_blank">
                    <i className="fa fa-twitter-square"></i>&nbsp; Twitter
                </a>
              </div>
            `,
      showCancelButton: true,
      confirmButtonText: "Close",
    });
  };

  return (
    // <nav className='nav-bar'>
    //     <Link to="/" className="mt-2 text-white"><h5>SmartAdmssions</h5></Link>
    //     <div>
    //         <ul id="nav-bar-links" className={clicked ? "#nav-bar-links active my-auto" : "#nav-bar-link my-auto"}>
    //             <h4 className="mt-2">Do you want to run your awards or sell your ticket in a smart way? visit
    //             <a href="https://www.opencastgh.com" target="_blank" rel="noreferrer noopener" className="ms-2">Opencast<span>GH</span></a>
    //             </h4>
    //         </ul>
    //     </div>
    //     <div className="mobile" onClick={handleClick}>
    //         <i id="bar" className={clicked ? "fas fa-times":"fas fa-bars"}></i>
    //     </div>
    // </nav>
    <div className="container-fluid">
      <div className="advert-margin">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link to="/" className="navbar-brand">
            Quick<span style={{ color: "dodgerblue" }}>SHS</span>Admit
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  href={() => {}}
                  onClick={handleDonation}
                >
                  Donate
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  target={"_blank"}
                  href={"https://opencastgh.com"}
                  // onClick={() => {}}
                  rel="noreferrer"
                >
                  OpenCastGh
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={showContactInfo}>
                  Contact Us
                </a>
              </li>
            </ul>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/login">
                  Login
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};
export default Nav;
