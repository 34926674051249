import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/auth/Login";
import EditHouses from "./pages/dashboard/admin/EditHouses";
import Houses from "./pages/dashboard/admin/Houses";
import DashboardLayout from "./pages/dashboard/partials/DashboardLayout";
import AddNewHouse from "./pages/dashboard/admin/AddNewHouse";
import Home from "./pages/dashboard/admin/Home";
import SignUp from "./pages/auth/SignUp";
import AdmissionList from "./pages/dashboard/admin/AdmissionList";
import Registration from "./pages/registrationComponents/Registration";
import PrintPage from "./pages/printout/PrintPage";
import routes from "./components/helpers/routes";
import axios from "axios";
import Resources from "./pages/resources/Resources";
import MedicalRecord from "./pages/printout/MedicalRecord";
import BulkVoucherPurchase from "./pages/dashboard/admin/BulkVoucherPurchase";
import SchoolSetUp from "./pages/dashboard/admin/SchoolSetUp";
import SuccessPage from "./pages/SuccessPage";
import ErrorPage from "./pages/ErrorPage";
import Privacy from "./pages/Privacy";

function App() {
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  // axios.defaults.baseURL = "http://127.0.0.1:8000";
  // axios.defaults.baseURL = "https://6e7c-154-160-10-35.ngrok-free.app";
  axios.defaults.baseURL = "https://admissions.samasoftech.net";

  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return (
    <Routes>
      <Route path="/" element={<Registration />} />
      <Route path="/print-page" element={<PrintPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/medical" element={<MedicalRecord />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/failed" element={<ErrorPage />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route path={routes.DASHBOARD} element={<Home />} />
        <Route path="/dashboard/houses" element={<Houses />} />
        <Route path="/dashboard/school-setup" element={<SchoolSetUp />} />
        <Route path="/dashboard/resources" element={<Resources />} />
        <Route path="/dashboard/add/house" element={<AddNewHouse />} />
        <Route path="/dashboard/house/:id/edit" element={<EditHouses />} />
        <Route path="/dashboard/admission-list" element={<AdmissionList />} />
        <Route
          path="/dashboard/bulk-voucher-purchase"
          element={<BulkVoucherPurchase />}
        />
      </Route>
    </Routes>
  );
}

export default App;
