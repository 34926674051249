import parse from "html-react-parser";
const OfferLetter = ({ data }) => {
  return (
    <div className="col-lg-12">
      <div className="card card-table table-height3">
        <div className="card-body booking_card">
          <div className="head-cover text-center">
            <div>
              {parse(
                data?.school?.resource?.header ||
                  '<h3 className="text-capitalize">Loading ...</h3>'
              )}
            </div>
            <div className="img-position">
              <img
                className="print-img img-fluid"
                src={`${process.env.REACT_APP_BACKEND_URL}/${data?.school?.image}`}
                alt=""
              />
            </div>
            <div className="offer-cover">
              <h5>OFFER OF ADMISSION 2023/ 2024</h5>
            </div>
          </div>
          <div className="body-content">
            <div className="div-content mt-5">
              <div className="div-content-3">
                <h4>
                  NAME : <span className="text-capitalize">{data?.name}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  INDEX N0 : <span>{data?.index_number}</span>
                </h4>
              </div>
            </div>
            <div className="my-3 offer-body">
              <h4>
                Following your success in the 2023 Basic Education Certificate
                Examination and selection by the Computerized School Selection
                and Placement System, (CSSPS) you have been offered admission to
                Afua Kobi Ampem Girls’ Senior High School, Trabuom to pursue a
                three-year programme for 2023/2024 academic
              </h4>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  PROGRAMME : <span>{data?.department}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  STATUS : <span>{data?.residencial_status}</span>
                </h4>
              </div>
            </div>
            <div className="my-3 offer-body">
              <h5>
                A student IDENTITY, NAME and PROGRAMME registered on admission
                cannot be changed.
              </h5>
            </div>
            <div className="my-3 offer-body">
              <h4>
                1. Parents should study the school rules and regulations with
                their wards for strict compliance.
              </h4>
              <h4>
                2. Bring along all the items on the attached prospectus.
                Housemistress will check the items on arrival.
              </h4>
              <h4>
                3. Boarders should report to the Housemistress at their
                respective houses in the school on 4th December, 2023. No
                lateness would be entertained.
              </h4>
              <h4 className="ms-3 mt-4">
                On behalf of the Board of Governors, I wish your ward a
                successful stay in AFUA KOBI AMPEM GIRLS’ SHS
              </h4>
              <h4 className="ms-3 mt-4">Accept my congratulations.</h4>
              <h4 className="ms-3 mt-5">
                MRS SYLVIA NEWTON <br /> HEADMISTRESS
              </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="body-content dev-name-pos text-center mb-4">
            <h4>
              Developed by Samasoft Technologies (0558571128 / 0554922935)
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OfferLetter;
