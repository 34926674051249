import axios from "axios";
import { useState } from "react";
import Button from "../../components/Button";
import { useEffect } from "react";

const PictureUpload = ({ setScreen, student, setStudent }) => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setPreviewUrl(URL.createObjectURL(e.target.files[0]));
  };
  useEffect(()=>{
    if(Number(student?.school?.student_picture_required)===0){
      setScreen('school_info');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsUploading(true);

    try {
      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("admission_id", student?.id);
        formData.append("school_id", student?.school_id);

        const response = await axios.post(
          "/api/v1/student/register",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response?.data?.status === 200) {
          setScreen("school_info");
          setStudent(response?.data?.data);
        }
      } else if (student?.student_details?.image) {
        setScreen("school_info");
      } else {
        window.alert("Please upload image to proceed");
      }
    } catch (err) {
      console?.log(err);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="card card-table table-height2">
      <div className="card-header card-header-margin d-flex justify-content-center">
        <h3 className="my-auto text-center">Upload Student's Image</h3>
      </div>
      <div className="card-body booking_card">
        <div className="main-cover">
          <div className="img-cover">
            {previewUrl ? (
              <img src={previewUrl} alt="student" width={"100%"} />
            ) : (
              <img
                src={
                  student?.student_details?.image === undefined
                    ? `/assets/images/default.png`
                    : `${process.env.REACT_APP_BACKEND_URL}/${student?.student_details?.image}`
                }
                width="100%"
                alt="student"
              />
            )}
          </div>
          <label
            htmlFor="pic-upload"
            className="btn btn-outline-primary button-position w-100"
          >
            Upload Image
          </label>
          <input
            type="file"
            id="pic-upload"
            name="picture"
            onChange={handleFileChange}
          />
        </div>
        <div className="col-lg-12 text-center">
          <Button
            cssClasses={"btn btn-success px-md-5"}
            buttonText="Save and Continue"
            isloading={isUploading}
            callback={handleUpload}
          />
        </div>
      </div>
    </div>
  );
};
export default PictureUpload;
