import React, { useEffect, useState } from "react";
import parse from 'html-react-parser';

const SisForm = ({data}) => {
  const [details,setDetails]=useState();

  useEffect(()=>{
    setDetails(data?.student_details);
  },[data]);

  return (
    <div className="col-lg-12">
      <div className="card card-table table-height3">
        <div className="card-body booking_card">
          <div className="head-cover text-center">
             <div>
              {parse(data?.school?.resource?.header|| '<h3 className="text-capitalize">Loading ...</h3>')}
            </div>
            <div className="img-position">
              <img
                className="print-img img-fluid"
                src={`${process.env.REACT_APP_BACKEND_URL}/${data?.school?.image}`}
                alt=""
              />
            </div>
            <div className="admiss">
              <b>SIS DATA FORM</b>
            </div>
          </div>
          <div className="body-content">
            <div className="stu_id">
              <h4>Enrollment Code</h4>
              <div className="enroll-box text-center enroll-font">{details?.enrollment_pin}</div>
            </div>
            <div className="div-content mt-5">
              <div className="div-content-3">
                <h4>
                  Full Name : <span className="text-capitalize">{data?.name}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  B.E.C.E Index Number : <span>{data?.index_number}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Gender : <span className="text-capitalize">{data?.gender}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  Name Of Last School Attended : <span className="text-capitalize">{details?.basic_school}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  Place Of Birth : <span className="text-capitalize">{details?.place_of_birth}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Date Of Birth : <span>{details?.date_of_birth}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  Residential Address : <span className="text-capitalize">{details?.residence}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Hometown : <span className="text-capitalize">{details?.hometown}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-1 d-flex">
                <h4>District :</h4>
                <div className="line2 my-auto ms-3"></div>
              </div>
              <div className="div-content-2">
                <h4>
                  Nationality : <span className="text-capitalize">{details?.nationality}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  Health Challenge : <span className="text-capitalize">{details?.physically_challenged}</span>
                </h4>
              </div>
            </div>
            <div className="mt-md-5 mt-3">
              <h3>Parent Information</h3>
            </div>
            <div>
              <h4>Father</h4>
            </div>
            <div className="div-content-cover">
              <div className="div-content">
                <div className="div-content-1">
                  <h4>
                    Full Name : <span className="text-capitalize">{details?.father_name}</span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Occupation : <span className="text-capitalize">{details?.father_occupation}</span>
                  </h4>
                </div>
              </div>
              <div className="div-content">
                <div className="div-content-1">
                  <h4>
                    Contact : <span>{details?.father_phone}</span>
                  </h4>
                </div>
                <div className="div-content-2 d-flex">
                  <h4>House No. :</h4>
                  <div className="line my-auto ms-3"></div>
                </div>
              </div>
            </div>
            <div className="mt-md-5 mt-3">
              <h4>Mother</h4>
            </div>
            <div className="div-content-cover">
              <div className="div-content">
                <div className="div-content-1">
                  <h4>
                    Full Name : <span className="text-capitalize"> {details?.mother_name}</span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Occupation : <span className="text-capitalize">{details?.mother_occupation}</span>
                  </h4>
                </div>
              </div>
              <div className="div-content">
                <div className="div-content-1">
                  <h4>
                    Contact : <span className="text-capitalize">{details?.mother_phone}</span>
                  </h4>
                </div>
                <div className="div-content-2 d-flex">
                  <h4>House No. :</h4>
                  <div className="line my-auto ms-3"></div>
                </div>
              </div>
            </div>
            <div className="mt-md-5 mt-3">
              <h3>Guardian Information</h3>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  Full Name : <span className="text-capitalize">{details?.guardian_name}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Occupation : <span className="text-capitalize">{details?.guardian_occupation}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-1">
                <h4>
                  Contact : <span>{details?.guardian_phone}</span>
                </h4>
              </div>
              <div className="div-content-2 d-flex">
                <h4>House No. :</h4>
                <div className="line my-auto ms-3"></div>
              </div>
            </div>
            <div className="mt-md-5 mt-3">
              <h3>Postal Address</h3>
            </div>
            <div className="div-content mt-md-5 mt-3">
              <div className="div-content">
                <h4>
                  Address : 
                </h4>
                <div className="line4 my-auto ms-3"></div>
              </div>
            </div>
            <div className="div-content mt-md-5 mt-3">
              <div className="div-content-3 d-flex">
                <h4>
                  Town : 
                </h4>
                <div className="line3 my-auto ms-3"></div>
              </div>
              <div className="div-content-3 d-flex">
                <h4>Region :</h4>
                <div className="line3 my-auto ms-3"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
              <div className="body-content dev-name-pos text-center mb-4">
                <h4>Developed by Samasoft Technologies (0558571128 / 0554922935)</h4>
              </div>
            </div>
      </div>
    </div>
  );
};
export default SisForm;
