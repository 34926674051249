import axios from "axios";
import React, { useState } from "react";
import Button from "../../../components/Button";

const RegistrationForm = ({
  errors,
  setErrors,
  setPage,
  schools,
  setScreen,
  setStudent,
  setLogo,
}) => {
  const [school, setSchool] = useState();
  const [isloading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    voucher_code: "",
    school_id: "",
    index_number: "",
  });

  const handleUserInput = (e) => {
    setErrors("");
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "school_id") {
      const school = schools?.filter((school) => {
        if (Number(school?.id) === Number(e.target.value)) {
          setSchool(school);
          return school;
        }
        return null;
      });
      setLogo(`${process.env.REACT_APP_BACKEND_URL}/${school?.[0]?.image}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (school?.require_code === "yes") {
      axios
        .post("/api/v1/voucher/verify", userInput)
        .then((res) => {
          localStorage.setItem("index_number", userInput?.index_number);
          setStudent(res?.data?.data);
          setScreen("picture-upload");
        })
        .catch((err) => {
          console.log(err?.response);
          if (err?.response?.status === 422) {
            setErrors(err?.response?.data?.errors);
          }
          if (err?.response?.status === 400) {
            alert(err?.response?.data?.error_msg);
          }
        })
        .finally(() => setLoading(false));
    }

    if (school?.require_code !== "yes") {
      axios
        .post("/api/v1/student/verify", userInput)
        .then((res) => {
          localStorage.setItem("index_number", userInput?.index_number);
          setStudent(res?.data?.data);
          setScreen("picture-upload");
        })
        .catch((err) => {
          console.log(err?.response);
          if (err?.response?.status === 422) {
            setErrors(err?.response?.data?.errors);
          }
          if (err?.response?.status === 400) {
            alert(err?.response?.data?.error_msg);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="formtype">
        <div className="form-group">
          <label htmlFor="">
            <h4>Select School</h4>
          </label>
          <select
            className="form-control"
            value={userInput?.school_id}
            name="school_id"
            required
            onChange={handleUserInput}
          >
            <option value={""}>--Select School--</option>
            {schools
              ?.filter((item) => Number(item?.id) !== Number(10))
              ?.map((school, i) => (
                <option
                  className="text-capitalize"
                  value={school?.id}
                  url={school?.image}
                  key={i}
                >
                  {school?.name}
                </option>
              ))}
          </select>
          <small className="text-danger">{errors?.school_id}</small>
        </div>

        {school?.require_code === "yes" && (
          <div className="form-group">
            <label htmlFor="">
              <h4>Enter Voucher Code</h4>
            </label>
            <input
              type="text"
              minLength={4}
              maxLength="20"
              value={userInput?.voucher_code}
              placeholder="XXXXXXXXXX"
              id="email"
              name="voucher_code"
              onChange={handleUserInput}
              required
              className="form-control text-left"
            />
            <small className="text-danger">{errors?.voucher_code}</small>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="">
            <h4 className="text-nowrap">BECE Index Number</h4>
          </label>
          <input
            type="text"
            minLength="10"
            maxLength="12"
            value={userInput?.index_number}
            placeholder="eg. 050301603323"
            id="email"
            name="index_number"
            onChange={handleUserInput}
            required
            className="form-control text-left"
          />
          <small className="text-danger">{errors?.index_number}</small>
        </div>
      </div>

      <div className="mt-4">
        <Button
          cssClasses={"btn btn-primary w-100"}
          isloading={isloading}
          buttonText={"Proceed to Registration Forms"}
        />
      </div>
      <div className="form-group text-center">
        <button
          className="btn btn-secondary mt-2 text-center"
          onClick={() => setPage("initial")}
        >
          Go Back
        </button>
      </div>
    </form>
  );
};

export default RegistrationForm;
