import { useEffect, useState } from "react";
import useGetFetch from "../../components/customHooks/useGetFetch";
import SnackBar from "../../components/SnackBar";
import GuardianInformation from "./GuardianInformation";
import ParentsInformation from "./ParentsInformation";
import PersonalInformation from "./PersonalInformation";
import PictureUpload from "./PictureUpload";
import SchoolInformation from "./SchoolInformation";
import Verification from "./Verification";

const Registration = () => {
  const [screen, setScreen] = useState("verification");
  const [student, setStudent] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarText, setSnackbarText] = useState("");
  const { data: schools } = useGetFetch("/api/v1/school/guest/all");

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
      <div className={`${screen === "verification" ? "" : "img-backbo"}`}>
        <SnackBar show={showSnackbar} text={snackBarText} />
        <div
          className={`${screen === "verification" ? "" : "container-fluid"}`}
        >
          <div className="">
            <div className="col-lg-12 bb">
              {screen === "verification" && (
                <Verification
                  setScreen={setScreen}
                  setStudent={setStudent}
                  schools={schools}
                  setShowSnackbar={setShowSnackbar}
                  setSnackbarText={setSnackbarText}
                />
              )}
              {screen === "picture-upload" && (
                <PictureUpload
                  setScreen={setScreen}
                  student={student}
                  setStudent={setStudent}
                />
              )}
              {screen === "school_info" && (
                <SchoolInformation
                  setScreen={setScreen}
                  student={student}
                  setStudent={setStudent}
                />
              )}
              {screen === "personal_info" && (
                <PersonalInformation
                  setScreen={setScreen}
                  student={student}
                  setStudent={setStudent}
                />
              )}
              {screen === "parents_info" && (
                <ParentsInformation
                  setScreen={setScreen}
                  student={student}
                  setStudent={setStudent}
                />
              )}
              {screen === "guardian_info" && (
                <GuardianInformation
                  setScreen={setScreen}
                  student={student}
                  setStudent={setStudent}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Registration;
