import { useState } from "react";
import Spinners from "../../../components/Spinners";
import axios from "axios";
import Swal from "sweetalert2";

const BulkVoucherPurchase = () => {
  const [isloading, setLoading] = useState(false);

  const [userInput, setUserInput] = useState({
    phone: "",
    quantity: 0,
  });

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePurchase = () => {
    setLoading(true);
    axios
      .post("/api/v1/bulk-voucher/purchase", userInput)
      .then((res) => {
        if (res?.data?.status === 200) {
          window.location.replace(res?.data?.data?.trans_details?.checkoutUrl);
        }

        Swal.fire({
          title: "Success",
          text: "Please wait and approve payment to recieve voucher code",
          icon: "success",
          confirmButtonColor: "#011535",
          confirmButtonText: "Okay",
        })
          .then(() => {
            Swal.fire({
              title: "Success",
              text: "Transaction Processing Failed",
              icon: "success",
              confirmButtonColor: "#011535",
              confirmButtonText: "Okay",
            });
          })
          .finally(() => [setLoading(false)]);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row mt-5">
            <div className="col-md-12 d-flex">
              <div className="card card-table flex-fill">
                <div className="card-header d-flex justify-content-between">
                  <div>
                    <h4 className="card-title float-left mt-2">
                      Click to purchase voucher code
                    </h4>
                  </div>
                  <div>
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      Buy Voucher
                    </button>
                  </div>
                </div>
                <div className="card-body booking_card">
                  {/* <div className="d-flex justify-content-center align-content-center">
                    <div className="w-50 mb-2">
                      <input
                        type="text"
                        className="form-control text-center"
                        placeholder="Search for voucher"
                      />
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      className="table table-hover table-center"
                      id="example"
                    >
                      <thead>
                        <tr>
                          <th>Phone number</th>
                          <th className="text-center">Total Quantity</th>
                          <th className="text-center">Amount</th>
                          <th className="text-center">Remaining Quantity</th>
                          <th className="text-center">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((ele, i) => (
                          <tr key={i}>
                            <td className="text-nowrap">0554922935</td>
                            <td className="text-center">6</td>
                            <td className="text-nowrap text-center text-capitalize">
                              ¢23.00
                            </td>
                            <td className="text-center">2</td>
                            <td className="text-center">27/03/22</td>
                          </tr>
                        ))}
                        {}
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Bulk Voucher Purchase
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row formtype">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Quantity</label>
                            <input
                              type="number"
                              name="quantity"
                              value={userInput?.quantity}
                              onChange={handleUserInput}
                              required
                              min={0}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Phone Number</label>
                            <input
                              type="number"
                              name="phone"
                              value={userInput?.phone}
                              onChange={handleUserInput}
                              required
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            {!isloading && (
                              <button
                                type="button"
                                className="btn btn-primary bg-default buttonedit1 w-100"
                                // data-dismiss="modal"
                                onClick={handlePurchase}
                              >
                                Proceed
                              </button>
                            )}
                            {isloading && (
                              <button
                                className="btn btn-primary bg-default buttonedit1 w-100"
                                disabled
                              >
                                {Spinners.rotatingLines}
                                <span className="my-auto"> Please Wait</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
    </>
  );
};
export default BulkVoucherPurchase;
