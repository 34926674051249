import axios from "axios";
import React, { useState } from "react";
import Button from "../../../components/Button";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
// import { isValidPhoneNumber } from "react-phone-number-input";

function RetrieveCode({ schools, setScreen, setStudent, setPage }) {
  const [isloading, setLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [phone, setPhone] = useState();
  const [voucher, setVoucher] = useState("");
  const [userInput, setUserInput] = useState({
    phone: "",
    index_number: "",
  });

  const handleUserInput = (e) => {
    setErrors("");
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/api/v1/voucher/retrieve", {
        phone: phone,
        index_number: userInput?.index_number,
      })
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === 200) {
          setVoucher(res?.data?.data?.voucher_code);
          console.log(res?.data);
        }
      })
      .catch((err) => {
        console.log(err?.response);
        setLoading(false);
        if (err?.response?.status === 422) {
          setErrors(err?.response?.data?.errors);
        }
        if (err?.response?.status === 400) {
          alert(err?.response?.data?.error_msg);
        }
      });
  };

  const processVoucher = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/api/v1/voucher/verify", {
        voucher_code: voucher,
        index_number: userInput?.index_number,
      })
      .then((res) => {
        console.log(res?.data);
        setLoading(false);
        setStudent(res?.data?.data);
        localStorage.setItem('index_number',userInput?.index_number);
        setScreen("picture-upload");
      })
      .catch((err) => {
        console.log(err?.response?.data);
        setLoading(false);
        if (err?.response?.status === 422) {
          setErrors(err?.response?.data?.errors);
        }
        if (err?.response?.status === 400) {
          alert(err?.response?.data?.error_msg);
        }
      });
  };

  return voucher === "" ? (
    <form onSubmit={handleSubmit}>
      <div className="formtype">
        <div className="form-group">
          <label htmlFor="">
            <h4>Enter Index Number</h4>
          </label>
          <input
            type="text"
            minLength="10"
            maxLength="12"
            value={userInput?.index_number}
            placeholder="eg. 050301603322"
            id="email"
            name="index_number"
            onChange={handleUserInput}
            required
            className="form-control text-left"
          />
          <small className="text-danger">{errors?.index_number}</small>
        </div>

        <div className="form-group">
          <label htmlFor="">
            <h4>Phone Number</h4>
            <small>(thus the Momo number used during the purchase)</small>
          </label>
          <PhoneInput
            country="GH"
            defaultCountry="GH"
            // countries={["GH"]}
            value={phone}
            onChange={setPhone}
            className="form-control text-left"
          />
          <div className="text-danger">
            {phone
              ? isValidPhoneNumber(phone)
                ? ""
                : "Invalid phone number"
              : "Phone number required"}
          </div>
          <small className="text-danger">{errors?.phone}</small>
        </div>

        <div className="mt-4">
          <Button
            cssClasses={"btn btn-primary w-100"}
            isloading={isloading}
            buttonText={"Retrieve Voucher Code"}
          />
        </div>
      </div>
      <div className="form-group text-center">
        <button
          className="btn btn-secondary mt-2 text-center"
          onClick={() => setPage("initial")}
        >
          Go Back
        </button>
      </div>
    </form>
  ) : (
    <>
      <div className="formtype">
        <div className="form-group">
          <label className="text-center justify-content-center align-items-center d-flex">
            <h4>Voucher Code</h4>
          </label>
          <input
            type="text"
            minLength="10"
            maxLength="12"
            value={voucher}
            readOnly
            placeholder="eg. 050301603323"
            id="email"
            name="voucher_code"
            required
            className="form-control text-center"
          />
          <small className="text-danger">{errors?.voucher_code}</small>
        </div>
      </div>
      <div className="mt-4">
        <Button
          cssClasses={"btn btn-primary w-100"}
          isloading={isloading}
          buttonText={"Access Registration Portal"}
          callback={processVoucher}
        />
      </div>
    </>
  );
}

export default RetrieveCode;
