import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const EditHouses = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [userInput, setUserInput] = useState();
  const [isloading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/v1/house/${id}`)
      .then((res) => {
        setUserInput(res?.data?.data);
      })
      .catch((err) => {
        console.log(err?.response);
        if (err?.response?.status === 400) {
          Swal.fire("Error", err?.response?.data?.error_msg, "error");
        }
      });
  }, [id]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      ?.post(`/api/v1/house/${id}/update`, userInput)
      .then((res) => {
        // if (res?.data?.status === 200) {
        Swal.fire("Success", res?.data?.msg, "success");
        // }
      })
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        console.log(err?.response);
        if (err?.response?.status === 422) {
          Swal.fire("Error", err?.response?.data?.message, "error");
        }
      })
      .finally((_) => {
        setLoading(false);
      });
  };

  return (
    <div className="page-wrapper">
      <div className="content container">
        <div className="page-header"></div>

        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="card card-shadow">
              <div className="card-header">
                <div className="d-flex flex-row justify-content-between">
                  <div className="p-2">
                    <h3>Update House Details</h3>
                  </div>
                  <div className="p-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row formtype">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Title</label>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                value={userInput?.title}
                                onChange={handleUserInput}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>House Master / Mistress Contact</label>
                              <input
                                type="number"
                                name="house_master_contact"
                                value={userInput?.house_master_contact}
                                onChange={handleUserInput}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Capacity</label>
                              <input
                                type="number"
                                name="capacity"
                                value={userInput?.capacity}
                                onChange={handleUserInput}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Gender</label>
                              <select
                                className="form-control"
                                name="gender_type"
                                value={userInput?.gender_type}
                                onChange={handleUserInput}
                                required
                              >
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Status</label>
                              <select
                                className="form-control"
                                name="is_active"
                                value={userInput?.is_active}
                                onChange={handleUserInput}
                                required
                              >
                                <option value="1">Active</option>
                                <option value="0">Blocked</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Is Day House</label>
                              <select
                                className="form-control"
                                name="is_day_house"
                                value={userInput?.is_day_house}
                                onChange={handleUserInput}
                                required
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </select>
                            </div>
                          </div>
                          <Button
                            buttonText={"Update House"}
                            cssClasses="btn btn-primary bg-default buttonedit1"
                            isloading={isloading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditHouses;
