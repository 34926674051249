import axios from "axios";
import React, { useState } from "react";
import Button from "../../../components/Button";
import PhoneInput from "react-phone-number-input";

const PurchaseVoucher = ({ setLogo, schools, setPage, setScreen }) => {
  const [school, setSchool] = useState();
  const [isloading, setLoading] = useState(false);
  const [phone, setPhone] = useState();
  const [userInput, setUserInput] = useState({
    school_id: "",
    phone: "",
    index_number: "",
  });

  const [errors, setErrors] = useState();

  const handleUserInput = (e) => {
    setErrors("");
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "school_id") {
      const school = schools?.filter((school) => {
        if (Number(school?.id) === Number(e.target.value)) {
          setSchool(school);
          return school;
        }
        return null;
      });
      setLogo(`${process.env.REACT_APP_BACKEND_URL}/${school?.[0]?.image}`);
    }
  };

  const handleVoucherPurchase = (e) => {
    e.preventDefault();
    setLoading(true);
    if (school?.require_code === "yes") {
      axios
        .post("/api/v1/voucher/purchase", {
          school_id: userInput?.school_id,
          phone: phone,
          index_number: userInput?.index_number,
        })
        .then((res) => {
          if (res?.data?.status === 200) {
            window.location.replace(
              res?.data?.data?.trans_details?.checkoutUrl
            );
          }
          console.log(res?.data);
        })
        .catch((err) => {
          console.log(err?.response);
          setLoading(false);
          if (err?.response?.status === 422) {
            setErrors(err?.response?.data?.errors);
          }
          if (err?.response?.status === 400) {
            alert(err?.response?.data?.error_msg);
          }
        });
    }

    if (school?.require_code !== "yes") {
      axios
        .post("/api/v1/student/verify", userInput)
        .then((res) => {
          if (res?.data?.status === 200) {
            localStorage.setItem("index_number", userInput?.index_number);
            setScreen("picture-upload");
          }
          console.log(res?.data);
        })
        .catch((err) => {
          console.log(err?.response);
          setLoading(false);
          if (err?.response?.status === 422) {
            setErrors(err?.response?.data?.errors);
          }
          if (err?.response?.status === 400) {
            alert(err?.response?.data?.error_msg);
          }
        });
    }
  };

  return (
    <form onSubmit={handleVoucherPurchase}>
      <div className="formtype">
        <div className="form-group">
          <label htmlFor="">
            <h4>Select School</h4>
          </label>
          <select
            className="form-control"
            value={userInput?.school_id}
            name="school_id"
            required
            onChange={handleUserInput}
          >
            <option value={""}>--Select School--</option>
            {schools
              ?.filter((item) => Number(item?.id) !== Number(10))
              ?.map((school, i) => (
                <option
                  className="text-capitalize"
                  value={school?.id}
                  url={school?.image}
                  key={i}
                >
                  {school?.name}
                </option>
              ))}
          </select>
          <small className="text-danger">{errors?.school_id}</small>
        </div>
        <div className="form-group">
          <label htmlFor="">
            <h4>Enter Telephone Number</h4>
          </label>
          <PhoneInput
            country="GH"
            defaultCountry="GH"
            // countries={["GH"]}
            required
            value={phone}
            onChange={setPhone}
            placeholder="eg. 0554922935"
            className="form-control text-left"
          />
          <small className="text-danger">{errors?.phone}</small>
        </div>
        <div className="form-group">
          <label htmlFor="">
            <h4>Enter Index Number</h4>
          </label>
          <input
            type="text"
            minLength="10"
            maxLength="12"
            value={userInput?.index_number}
            placeholder="eg. 050301603323"
            id="email"
            name="index_number"
            onChange={handleUserInput}
            required
            className="form-control text-left"
          />
          <small className="text-danger">{errors?.index_number}</small>
        </div>

        {school?.require_code === "yes" ? (
          <div className="mt-4">
            <Button
              cssClasses={"btn btn-primary w-100 text-nowrap"}
              isloading={isloading}
              buttonText={"Proceed to Voucher Purchase"}
            />
          </div>
        ) : (
          <div className="mt-4">
            <Button
              cssClasses={"btn btn-primary w-100 text-nowrap"}
              isloading={isloading}
              buttonText={"Verify Student"}
            />
          </div>
        )}

        <div className="form-group text-center">
          <button
            className="btn btn-secondary mt-2 text-center text-nowrap"
            onClick={() => setPage("initial")}
          >
            Go Back
          </button>
        </div>
      </div>
    </form>
  );
};

export default PurchaseVoucher;
