const MedicalRecord = () => {
    return (
      <div className="col-lg-12">
        <div className="card card-table table-height3">
          <div className="card-body booking_card">
            <div className="body-content">
              <div className="stu_id">
                <h1 className="text-success">Patient Medical Record</h1>
              </div>
              <div className="div-content mt-3 mb-5">
                <div className="div-content-fit">
                  <h4 className="text-success">
                    Patient Information
                    <p className="mt-2">Goldie R Chisolm</p>
                    <p>(706) 296-9964</p>
                    <p>
                      1195 Holly Street <span>Athens, Georgia(GA), 30601</span>{" "}
                      United States
                    </p>
                  </h4>
                </div>
                <div className="div-content-fit">
                  <h4 className="text-success">
                    Birth Date
                    <p className="mt-2">March 21 1972</p>
                  </h4>
                  <h4 className="text-success">
                    Weight:
                    <p className="mt-2">62.69</p>
                  </h4>
                  <h4 className="text-success">
                    Height:
                    <p className="mt-2">157</p>
                  </h4>
                </div>
              </div>
              <div className="stu_id">
                <h5 className="text-danger">In Case of Emergency</h5>
                <hr className="back-hr" />
              </div>
              <div className="div-content mt-3 mb-5">
                <div className="div-content-fit">
                  <p>Bertie C Rowell</p>
                  <h4 className="text-success mt-4">
                    Home phone
                    <p className="mt-2">(202) 452-9485</p>
                  </h4>
                </div>
                <div className="div-content-fit">
                  <p className="mt-2">
                    4026 Hickory Lane{" "}
                    <span>Washington, Washington DC(DC), 20036</span> United
                    States
                  </p>
                  <h4 className="text-success mt-4">
                    Work phone
                    <p className="mt-2">(202) 452-9485</p>
                  </h4>
                </div>
              </div>
              <div className="stu_id">
                <h5 className="text-success">General Medical History</h5>
                <hr className="back-hr1" />
              </div>
              <div className="div-content mt-3">
                <div className="div-content-fit">
                  <h4 className="text-success">
                    Chicken Pox (Varicella):
                    <p className="bg-secondary p-1 d-width mt-2">NOT IMMUNE</p>
                  </h4>
                </div>
                <div className="div-content-fit">
                  <h4 className="text-success">
                    Measles:
                    <p className="bg-secondary p-1 d-width mt-2">NOT IMMUNE</p>
                  </h4>
                </div>  
              </div>
              <div className="div-content mt-3">
                <div className="div-content-fit1">
                  <h4 className="text-success">
                    Have you had the Hepatitis B vaccination?
                    <p className="bg-secondary p-1 d-width mt-2">NO</p>
                  </h4>
                </div>  
              </div>
              <div className="div-content mt-2">
                <div className="div-content-fit1">
                  <h4 className="text-success">
                    List any Medical Problems (asthma, seizures, headaches):
                    <p className="mt-5">
                      <div className="space-line"></div>
                      <div className="space-line mt-5"></div>
                      <div className="space-line mt-5"></div>
                      <div className="space-line mt-5"></div>
                      <div className="space-line mt-5"></div>
                      <div className="space-line mt-5"></div>
                      <div className="space-line mt-5"></div>
                      <div className="space-line mt-5"></div>
                      <div className="space-line mt-5"></div>
                      <div className="space-line mt-5"></div>
                      <div className="space-line mt-5"></div>
                    </p>
                  </h4>
                </div>  
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="body-content dev-name-pos text-center mb-4">
              <h4>
                Developed by Samasoft Technologies (0558571128 / 0554922935)
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default MedicalRecord;
  