import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import { useEffect } from "react";
import Spinners from "../../../components/Spinners";
const SchoolSetUp = () => {
  const [isloading, setLoading] = useState(false);
  const [loadDetails, setLoadDetails] = useState(false);
  const [selectedImage] = useState(null);
  const [errors] = useState();

  const [userInput, setUserInput] = useState([]);

  const fetchSchoolDetails = () => {
    setLoadDetails(false);
    axios
      .get(`/api/v1/school/${localStorage.getItem("school_id")}`)
      .then((res) => {
        setUserInput(res.data.data);
        setLoadDetails(true);
      })
      .catch((err) => {
        setLoadDetails(true);
        console.log(err.response);
      });
  };

  useEffect(() => {
    fetchSchoolDetails();
  }, []);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", userInput.name);
    data.append("school_id", userInput.id);
    data.append(
      "assign_house_to_day_students",
      userInput.assign_house_to_day_students
    );
    data.append("student_picture_required", userInput.student_picture_required);
    selectedImage && data.append("image", selectedImage);
    setLoading(true);
    axios
      .post("/api/v1/school/details/update", data)
      .then((res) => {
        if (res.data.status === 200 && res.data.success === true) {
          setLoading(false);
          let msg = res.data.msg;
          Swal.fire({
            title: "Success",
            text: msg,
            icon: "success",
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
          }).then(() => {
            fetchSchoolDetails();
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        Swal.fire({
          title: "Error",
          text: err.response.data.message,
          icon: "error",
          confirmButtonColor: "#011535",
          confirmButtonText: "Okay",
        });
        setLoading(false);
      });
  };

  return (
    <div className="page-wrapper mt-5 mt-md-0">
      <div className="content container">
        <div className="page-header"></div>

        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="card card-shadow card-h">
              <div className="card-header">
                <div className="p-2">
                  <h3>Update School Details</h3>
                </div>
              </div>
              <div className="card-body">
                {loadDetails ? (
                  <>
                    <form>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row formtype align-items-center justify-content-center">
                              <div className="col-md-6 mb-3">
                                <div
                                  style={{
                                    boxShadow:
                                      "0px 3px 10px 0px rgb(0 0 0 / 20%)",
                                    maxWidth: "40%",
                                    maxHeight: "150%",
                                  }}
                                  className="align-items-center justify-content-center"
                                >
                                  {selectedImage ? (
                                    <img
                                      src={URL.createObjectURL(selectedImage)}
                                      alt="uploaded-file"
                                      width="100%"
                                    />
                                  ) : (
                                    <img
                                      src={`${process.env.REACT_APP_BACKEND_URL}/${userInput?.image}`}
                                      alt="uploaded-file"
                                      width="100%"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Name Of School</label>
                                  <input
                                    type="text"
                                    name="name"
                                    value={userInput?.name}
                                    onChange={handleUserInput}
                                    required
                                    readOnly
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <small className="text-danger text-center">
                                {errors?.name}
                              </small>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Is Student Picture Required?</label>
                                  <select
                                    className="form-control"
                                    required
                                    name="student_picture_required"
                                    value={userInput?.student_picture_required}
                                    onChange={handleUserInput}
                                  >
                                    <option value="">-- Select Option--</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                  </select>
                                </div>
                                <small className="text-danger text-center">
                                  {errors?.student_picture_required}
                                </small>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    Automatically Assign House To Day Student?
                                  </label>
                                  <select
                                    className="form-control"
                                    required
                                    name="assign_house_to_day_students"
                                    value={
                                      userInput?.assign_house_to_day_students
                                    }
                                    onChange={handleUserInput}
                                  >
                                    <option value="">-- Select Option--</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                  </select>
                                </div>
                                <small className="text-danger text-center">
                                  {errors?.assign_house_to_day_students}
                                </small>
                              </div>

                              {!isloading && (
                                <button
                                  type="submit"
                                  className="btn btn-primary bg-default buttonedit1"
                                  onClick={handleSubmit}
                                >
                                  Update
                                </button>
                              )}
                              {isloading && (
                                <button
                                  className="btn btn-primary bg-default buttonedit1"
                                  disabled
                                >
                                  {Spinners.rotatingLines}
                                  <span className="my-auto"> Updating</span>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                ) : (
                  <div className="no-data">{Spinners.rotatingLines}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SchoolSetUp;
